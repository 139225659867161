import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReportState {
  isEditingNonPayRollReport: boolean;
  isEditingPayRollReport: boolean;
  timeNonPayrollTrigger: number;
  timePayrollTrigger: number;
  nonPayrollFormValues: any;
  payrollFormValues: any;
  loadingCheckin: boolean;
}

const initialState: IReportState = {
  isEditingNonPayRollReport: false,
  isEditingPayRollReport: false,
  timeNonPayrollTrigger: undefined,
  timePayrollTrigger: undefined,
  nonPayrollFormValues: null,
  payrollFormValues: null,
  loadingCheckin: false,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setIsDisableNonPayRollReport: (state, action: PayloadAction<boolean>) => {
      state.isEditingNonPayRollReport = action.payload;
    },
    setIsDisablePayRollReport: (state, action: PayloadAction<boolean>) => {
      state.isEditingPayRollReport = action.payload;
    },
    triggerNonPayRollReport: (state, action) => {
      state.timeNonPayrollTrigger = action.payload;
    },
    triggerPayRollReport: (state, action) => {
      state.timePayrollTrigger = action.payload;
    },
    setNonPayrollFormValues: (state, action) => {
      state.nonPayrollFormValues = action.payload;
    },
    setPayrollFormValues: (state, action) => {
      state.payrollFormValues = action.payload;
    },
    setLoadingCheckin: (state, action) => {
      state.loadingCheckin = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsDisableNonPayRollReport,
  setIsDisablePayRollReport,
  triggerNonPayRollReport,
  triggerPayRollReport,
  setNonPayrollFormValues,
  setPayrollFormValues,
  setLoadingCheckin,
} = reportSlice.actions;

export const reportState = reportSlice.getInitialState();

export default reportSlice.reducer;
