import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { StaffListingResponse } from './types';

export function useGetStaffListing(
  options?: UseQueryOptions<ApiResponseType<StaffListingResponse>, Error, StaffListingResponse>
) {
  const {
    data: staffListing,
    error,
    isFetching,
    refetch: onGetStaffListing,
  } = useQuery<ApiResponseType<StaffListingResponse>, Error, StaffListingResponse>(
    [API_QUERIES.GET_STAFF_LISTING],
    {
      queryFn: () => {
        return responseWrapper<ApiResponseType<StaffListingResponse>>(apiClient.getStaffListing);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: true,
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateStaffListing = () =>
    queryClient.invalidateQueries(API_QUERIES.GET_STAFF_LISTING);

  return {
    staffListing,
    error,
    isFetching,
    onGetStaffListing,
    handleInvalidateStaffListing,
  };
}
