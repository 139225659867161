import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const useCombinationKeyPress = (keys, callback, node = null, haveAltKey = true) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event) => {
      if (haveAltKey && event.altKey === true && keys.some((key) => event.key === key)) {
        callbackRef.current(event);
        return;
      }
      if (!haveAltKey && keys.some((key) => event.key === key)) {
        callbackRef.current(event);
      }
    },
    [keys, haveAltKey]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useCombinationKeyPress;
