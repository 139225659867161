import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { UHReportParams } from 'src/containers/UHReportContainer/types';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { KFSMonthlyExpendituresReportResponse } from './types';

export function useGetKFSMonthlyExpendituresReport(
  options?: UseQueryOptions<
    ApiResponseType<KFSMonthlyExpendituresReportResponse>,
    Error,
    KFSMonthlyExpendituresReportResponse
  > &
    Partial<UHReportParams>
) {
  const role = RoleService.getCurrentRole();
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<
    ApiResponseType<KFSMonthlyExpendituresReportResponse>,
    Error,
    KFSMonthlyExpendituresReportResponse
  >(
    [
      API_QUERIES.KFS_MONTHLY_EXPENDITURES_REPORT,
      {
        project: options.project,
        month: options.month,
        year: options.year,
        category: options.category,
        role: role,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<KFSMonthlyExpendituresReportResponse>>(
          apiClient.getKFSMonthlyExpendituresReport,
          params
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateKFSMonthlyExpendituresReport = () =>
    queryClient.invalidateQueries(API_QUERIES.KFS_MONTHLY_EXPENDITURES_REPORT);

  return {
    data,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateKFSMonthlyExpendituresReport,
  };
}
