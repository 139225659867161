import { memo } from 'react';
import { UseControllerProps, UseFormSetValue, useController } from 'react-hook-form';
import { Checkbox } from 'src/components/common';
import { CheckboxGroupProps } from 'src/components/common/Checkbox';

const FormCheckboxGroup = ({ control, setValue, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <Checkbox.Group
      label={props.label}
      {...props}
      {...field}
      onChange={setValue}
      errorMessage={fieldState.error?.message}
    />
  );
};

type Props<T = any> = UseControllerProps<T> &
  CheckboxGroupProps & {
    setValue: UseFormSetValue<T>;
  };

export default memo(FormCheckboxGroup);
