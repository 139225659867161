import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TravelRequestDetailResponse } from './types';

export function useGetTravelRequestDetail(
  options?: UseQueryOptions<
    ApiResponseType<TravelRequestDetailResponse>,
    Error,
    TravelRequestDetailResponse
  > & {
    id: string;
  }
) {
  const {
    data: poDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTravelRequestById,
  } = useQuery<ApiResponseType<TravelRequestDetailResponse>, Error, TravelRequestDetailResponse>(
    [API_QUERIES.TRAVEL_REQUEST, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<TravelRequestDetailResponse>>(
          apiClient.getTravelRequestDetail,
          params
        );
      },
      select: getResponseData,
      enabled: false,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateTravelRequestDetail = (id) =>
    queryClient.invalidateQueries([API_QUERIES.TRAVEL_REQUEST, { id }]);

  return {
    poDetail,
    error,
    isError,
    isLoading,
    onGetTravelRequestById,
    handleInvalidateTravelRequestDetail,
  };
}
