import { memo } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { EllipsisTooltipInput, EllipsisTooltipInputProps } from 'src/components/common/Input';

const EllipsisTooltipFormInput = ({ control, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <EllipsisTooltipInput
      label={props.label}
      {...props}
      {...field}
      errorMessage={fieldState.error?.message}
    />
  );
};

type Props<T = any> = UseControllerProps<T> & EllipsisTooltipInputProps;

export default memo(EllipsisTooltipFormInput);
