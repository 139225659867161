import { CancelToken } from 'apisauce';
import dayjs from 'dayjs';
import appConfig from 'src/appConfig';
import { FinancialProject } from 'src/queries';
import { isEmpty } from 'src/validations';

export function newCancelToken(timeout = appConfig.CONNECTION_TIMEOUT) {
  const source = CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, timeout);

  return { cancelToken: source.token };
}

export const stringify = (
  params: { [key: string]: number | string | string[] | boolean },
  excludeKey: string[] = []
) => {
  let result = '';

  if (!params) return '';

  Object.keys(params).forEach((key) => {
    if (!isEmpty(params[`${key}`]) || excludeKey.includes(`${key}`)) {
      if (Array.isArray(params[`${key}`])) {
        let array = params[`${key}`] as string[];
        array.forEach((param: string) => {
          result += `&${key}=${encodeURIComponent(param)}`;
        });
      } else {
        result += `&${key}=${encodeURIComponent(params[`${key}`]?.toString())}`;
      }
    }
  });

  result = result.replace(/^&/, '');

  return result;
};

export const trimValues = (values: { [key: string]: any }, arrKey: string[]) => {
  return Object.keys(values).reduce((acc, key) => {
    // eslint-disable-next-line security/detect-object-injection
    if (values[key] && typeof values[key] === 'string' && arrKey.includes(key)) {
      // eslint-disable-next-line security/detect-object-injection
      acc[key] = values[key].trim();
    } else {
      // eslint-disable-next-line security/detect-object-injection
      acc[key] = values[key];
    }
    return acc;
  }, {});
};

export const handleConvertProjectNumber = (projectNumber: string | FinancialProject) => {
  if (typeof projectNumber === 'string') {
    return projectNumber;
  }
  return projectNumber?.number;
};

export const keysLineItemForm = {
  projectLineItemsPo: ['budgetCategory', 'description', 'ext'],
  projectLineItemsNonPo: ['projectNumber', 'budgetCategory', 'amount'],
  projectLineItemsTravel: ['projectNumber', 'budgetCategory', 'amount'],
  remittanceLineItemsPoPayment: ['referenceNumber', 'amount'],
  remittanceLineItemsNonPo: ['referenceNumber', 'amount'],
  remittanceLineItemsNonPoPettyCash: ['amount'],
  remittanceLineItemsNonEmp: ['amount'],
  remittanceLineItemsPerAuto: ['amount'],
  remittanceLineItemsTravel: ['referenceNumber', 'amount'],
  faCURemittance: ['amount'],
  mileagesPerAuto: [
    'serviceDate',
    'tripFrom',
    'tripTo',
    'purpose',
    'roundTripFlag',
    'parking',
    'miles',
  ],
  mileagesTravel: [
    'serviceDate',
    'tripFrom',
    'tripTo',
    'purpose',
    'roundTripFlag',
    'parking',
    'miles',
  ],
  expenditures: ['item', 'leg', 'paymentMethod', 'description', 'amount'],
};

export const handleRemoveLastValueForValidation = <T>(
  arr: T[],
  numberOfLimitRow: number,
  keys: string[]
) => {
  if (
    arr.length <= numberOfLimitRow &&
    handleCheckObjectValueEmptyExceptKeyForValidation(arr[arr.length - 1], keys)
  ) {
    return arr.slice(0, -1);
  }
  return arr;
};

export const handleRemoveLastValue = <T>(arr: T[], numberOfLimitRow: number, keys: string[]) => {
  if (
    arr.length <= numberOfLimitRow &&
    handleCheckObjectValueEmptyExceptKey(arr[arr.length - 1], keys)
  ) {
    return arr.slice(0, -1);
  }
  return arr;
};

export const handleCheckObjectValueEmpty = (obj: any) => {
  return Boolean(obj) && Object.values(obj).every((x) => !x && Number(x) === 0);
};

export const handleCheckObjectValueEmptyExceptKey = (obj: Record<string, any>, keys: string[]) => {
  // eslint-disable-next-line security/detect-object-injection
  return Boolean(obj) && keys.every((key) => !obj[key] && obj[key] !== 0);
};

export const handleCheckObjectValueEmptyExceptKeyForValidation = (
  obj: Record<string, any>,
  keys: string[]
) => {
  // eslint-disable-next-line security/detect-object-injection
  return Boolean(obj) && keys.every((key) => !obj[key]);
};

export const handleAddLineItem = ({
  arr = [],
  addedArr = [],
  numberOfLimitRow = 0,
  isFinalMode = false,
}: {
  arr: any[];
  addedArr: any[];
  numberOfLimitRow: number;
  isFinalMode?: boolean;
}) => {
  if (arr.length < numberOfLimitRow) {
    return isFinalMode ? addedArr.slice(0, -1) : addedArr;
  }
  return arr;
};

export const handleSortFileAttachments = (fileAttachments: any[]) => {
  return fileAttachments.slice().sort((cur, next) => {
    return dayjs(cur.uploadDate).isBefore(dayjs(next.uploadDate)) ? -1 : 1;
  });
};
