import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useDeletePOPayment(
  options?: UseMutationOptions<any, Error, { id: string; updateVersionNumber: number }>
) {
  const { mutate: deletePOPayment, isLoading } = useMutation<
    any,
    Error,
    { id: string; updateVersionNumber: number }
  >({
    mutationFn: (payload: { id: string; updateVersionNumber: number }) =>
      responseWrapper(apiClient.deletePOPayment, [payload]),
    ...options,
  });

  return {
    deletePOPayment,
    isLoading,
  };
}
