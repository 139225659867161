import { memo } from 'react';
import { UseControllerProps, useController, UseFormSetValue } from 'react-hook-form';
import { DatePicker } from 'src/components/common';
import { DatePickerProps } from 'src/components/common/DatePicker';

const FormDatePicker = ({ control, setValue, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <DatePicker
      label={props.label}
      {...props}
      {...field}
      onChange={setValue}
      selected={field.value}
      errorMessage={fieldState.error?.message}
    />
  );
};

type Props<T = any> = UseControllerProps<T> &
  Omit<DatePickerProps, 'onChange'> & {
    setValue: UseFormSetValue<T>;
  };

export default memo(FormDatePicker);
