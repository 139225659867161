import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { TravelCompletionDetailResponse } from './types';

export function useGetTravelCompletionDetail(
  options?: UseQueryOptions<
    ApiResponseType<TravelCompletionDetailResponse>,
    Error,
    TravelCompletionDetailResponse
  > & {
    id: string;
  }
) {
  const {
    data: travelCompletionDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTravelCompletionById,
  } = useQuery<
    ApiResponseType<TravelCompletionDetailResponse>,
    Error,
    TravelCompletionDetailResponse
  >([API_QUERIES.TRAVEL_COMPLETION, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<TravelCompletionDetailResponse>>(
        apiClient.getTravelCompletionDetail,
        params
      );
    },
    select: getResponseData,
    enabled: false,
    ...options,
  });

  const queryClient = useQueryClient();
  const handleInvalidateTravelCompletionDetail = (id: string) =>
    queryClient.invalidateQueries([API_QUERIES.TRAVEL_COMPLETION, { id }]);

  return {
    travelCompletionDetail,
    error,
    isError,
    isLoading,
    onGetTravelCompletionById,
    handleInvalidateTravelCompletionDetail,
  };
}
