import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, authResponseWrapper } from '../helpers';
import { UrlExport } from './types';

export function useGetUrlExportUsers(
  options?: UseMutationOptions<ApiResponseType<{ data: UrlExport }>, Error, string>
) {
  const { mutate: getUrlExportUsers, isLoading } = useMutation<
    ApiResponseType<{ data: UrlExport }>,
    Error,
    string
  >({
    mutationFn: () => {
      return authResponseWrapper<ApiResponseType<{ data: UrlExport }>>(apiClient.getUrlExportUsers);
    },
    ...options,
  });

  return {
    getUrlExportUsers,
    isLoading,
  };
}
