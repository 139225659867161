import { memo } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { InputMask } from 'src/components/common';
import { InputMaskProps } from 'src/components/common/InputMask';

const FormInputMask = ({ control, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <InputMask label={props.label} {...props} {...field} errorMessage={fieldState.error?.message} />
  );
};

type Props<T = any> = UseControllerProps<T> & InputMaskProps;

export default memo(FormInputMask);
