import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { CurrentCheckPrinting } from './types';

export function useGetCurrentCheckPrinting(
  options?: UseQueryOptions<ApiResponseType<CurrentCheckPrinting>, Error, CurrentCheckPrinting>
) {
  const {
    data: currentCheckPrinting,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetCurrentCheckPrinting,
  } = useQuery<ApiResponseType<CurrentCheckPrinting>, Error, CurrentCheckPrinting>(
    [API_QUERIES.GET_CURRENT_CHECK_PRINTING],
    {
      queryFn: () => {
        return responseWrapper<ApiResponseType<CurrentCheckPrinting>>(
          apiClient.getCurrentCheckPrinting
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateGetCurrentCheckPrinting = () =>
    queryClient.invalidateQueries(API_QUERIES.GET_CURRENT_CHECK_PRINTING);

  return {
    currentCheckPrinting,
    error,
    isError,
    isLoading,
    onGetCurrentCheckPrinting,
    handleInvalidateGetCurrentCheckPrinting,
  };
}
