import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Navigator } from 'src/services';
import { ErrorEmptyPageMessage, NavigateQuery } from './types';

export default ({ query, isNotFoundPage }: { query: any; isNotFoundPage: boolean }) => {
  const history = useHistory();

  useEffect(() => {
    if (!isNotFoundPage) return;
    return history.listen(() => {
      if (history.action === 'POP') {
        const prevPage = query.get(NavigateQuery.PREVIOUS_PAGE);
        if (!!prevPage) {
          const searchText = query.get(NavigateQuery.SEARCH_TEXT) || '';

          Navigator.navigate(`${prevPage}?${searchText}`);
          return;
        }

        // Navigator.goBack();
        // Navigator to dashboard
      }
    });
  }, [history, isNotFoundPage, query]);
};

export const checkErrorMessagesNavigateEmptyPage = (message: string) => {
  return Object.values(ErrorEmptyPageMessage).some((messageItem) => message.includes(messageItem));
};
