import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { CHECK_REGISTER_DETAIL_VIEW, CheckRegisterDetail } from './types';

export function useGetCheckRegisterDetail(
  options?: UseQueryOptions<ApiResponseType<CheckRegisterDetail>, Error, CheckRegisterDetail> & {
    id: string;
    view: CHECK_REGISTER_DETAIL_VIEW;
  }
) {
  const {
    data: checkRegisterDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetCheckRegisterById,
  } = useQuery<ApiResponseType<CheckRegisterDetail>, Error, CheckRegisterDetail>(
    [API_QUERIES.GET_CHECK_REGISTER_DETAIL, { id: options.id, view: options.view }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<CheckRegisterDetail>>(
          apiClient.getCheckRegisterDetail,
          params
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateCheckRegisterDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.GET_CHECK_REGISTER_DETAIL);

  return {
    checkRegisterDetail,
    error,
    isError,
    isLoading,
    onGetCheckRegisterById,
    handleInvalidateCheckRegisterDetail,
  };
}
