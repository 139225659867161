import { MutateOptions, useMutation } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { ApiResponseType } from './../helpers';

export const useUpdateVendorRecord = (
  options?: MutateOptions<ApiResponseType<any>, Error, any>
) => {
  const {
    mutate: updateVendorRecord,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<any>, Error, any>({
    mutationFn: (payload: any) => responseWrapper(apiClient.updateVendorRecord, [payload]),
    ...options,
  });

  return {
    updateVendorRecord,
    data,
    isLoading,
    isSuccess,
  };
};
