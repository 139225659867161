import { memo } from 'react';
import { UseControllerProps, useController, UseFormSetValue } from 'react-hook-form';
import { RadioButton } from 'src/components/common';
import { RadioGroupProps } from 'src/components/common/RadioButton';

const FormRadioButton = ({ control, setValue, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <RadioButton
      label={props.label}
      {...props}
      {...field}
      onChange={setValue}
      errorMessage={fieldState.error?.message}
    />
  );
};

type Props<T = any> = UseControllerProps<T> &
  RadioGroupProps & {
    setValue: UseFormSetValue<T>;
  };

export default memo(FormRadioButton);
