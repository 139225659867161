import { Stack, Typography } from '@mui/material';
import React from 'react';
import { View } from 'src/components/common';
import Loading, { LoadingProps } from 'src/components/common/Loading';

const LoadingReport: React.FC<LoadingProps> = ({ loadingStyle = 5, className, ...props }) => {
  return (
    <View className={className}>
      <Stack alignItems={'center'}>
        <Loading variant="primary" size="medium" loadingStyle={loadingStyle} {...props} />
        <Typography mt={1} mb={3}>
          Loading Report
        </Typography>
      </Stack>
    </View>
  );
};

export default LoadingReport;
