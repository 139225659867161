import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { AuthorizationPaymentResponse } from './types';

//use case: for get detail data without caching
export function useGetAuthorizationPaymentDetailMutation(
  options?: UseMutationOptions<ApiResponseType<AuthorizationPaymentResponse>, Error, { id: string }>
) {
  const {
    mutate: getAuthorizationPaymentDetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<AuthorizationPaymentResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) =>
      responseWrapper(apiClient.getAuthorizationPaymentDetail, [params]),
    ...options,
  });

  return {
    getAuthorizationPaymentDetailMutation,
    isLoading,
    isSuccess,
  };
}
