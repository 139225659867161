import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useGetNetworkStatus(options?: UseMutationOptions<any, Error, any>) {
  const { mutate: getNetworkStatus, isLoading } = useMutation<any, Error, any>({
    mutationFn: (payload) => {
      return authResponseWrapper(apiClient.getNetworkStatus, [payload]);
    },
    ...options,
  });

  return {
    getNetworkStatus,
    isLoading,
  };
}
