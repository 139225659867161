import { Box } from '@mui/material';
import { COLOR_CODE } from 'src/appConfig/constants';
import { SelectOption } from 'src/components/common/Select';
import { FinancialProject } from 'src/queries';
import { DateFormat, getDateWithoutTimeZone } from 'src/utils';
import { isEmpty } from 'src/validations';

export const getFinancialProjectNumberOptions = ({
  projects,
}: {
  projects: FinancialProject[];
}): SelectOption[] => {
  if (isEmpty(projects)) return [];

  return projects.map((project) => ({
    label: (
      <Box>
        <span className="mr-4">{project.number}</span>
        {isTerminatedProject({ project: project }) && (
          <span style={{ color: COLOR_CODE.DANGER, marginRight: '2px' }}>(terminated)</span>
        )}
        {project.inactive === true && (
          <span style={{ color: COLOR_CODE.DANGER, marginRight: '2px' }}>(inactive)</span>
        )}
        <span>{project.name}</span>
      </Box>
    ),
    value: project,
    subLabel: `(${getDateWithoutTimeZone(project.startDate, DateFormat)} - ${getDateWithoutTimeZone(
      project.endDate,
      DateFormat
    )})`,
  }));
};

export const isTerminatedProject = ({ project }: { project: FinancialProject }) =>
  project.ac.trim().length > 0;
