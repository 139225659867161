import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { PettyCashDetailResponse } from './types';

//use case: for get detail data without caching
export function useGetPettyCashDetailMutation(
  options?: UseMutationOptions<ApiResponseType<PettyCashDetailResponse>, Error, { id: string }>
) {
  const {
    mutate: getPettyCashDetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<PettyCashDetailResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) => responseWrapper(apiClient.getPettyCashDetail, [params]),
    ...options,
  });

  return {
    getPettyCashDetailMutation,
    isLoading,
    isSuccess,
  };
}
