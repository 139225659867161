import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { TravelRequestDetailResponse, UpsertTravelRequestPayload } from './types';

export function useCreateTravelRequest(
  options?: UseMutationOptions<
    ApiResponseType<TravelRequestDetailResponse>,
    Error,
    UpsertTravelRequestPayload
  >
) {
  const {
    mutate: createTravelRequest,
    data,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<TravelRequestDetailResponse>, Error, UpsertTravelRequestPayload>({
    mutationFn: (payload: UpsertTravelRequestPayload) =>
      responseWrapper(apiClient.createTravelRequest, [payload]),
    ...options,
  });

  return {
    createTravelRequest,
    data,
    isLoading,
    isSuccess,
  };
}
