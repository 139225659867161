import dayjs from 'dayjs';

export const isEmpty = (value: any): boolean => {
  return value instanceof Date
    ? !dayjs(value).isValid()
    : !value ||
        value === undefined ||
        value === null ||
        value === 'undefined' ||
        value === 'null' ||
        Number.isNaN(value) ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value === '') ||
        (Array.isArray(value) && value.length === 0);
};

export const isNumeric = (num: any) => {
  return !isNaN(num);
};
