import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { POPaymentResponse } from './types';

//use case: for get detail data without caching
export function useGetPOPaymentDetailMutation(
  options?: UseMutationOptions<ApiResponseType<POPaymentResponse>, Error, { id: string }>
) {
  const {
    mutate: getPOPaymentDetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<POPaymentResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) => responseWrapper(apiClient.getPOPaymentDetail, [params]),
    ...options,
  });

  return {
    getPOPaymentDetailMutation,
    isLoading,
    isSuccess,
  };
}
