import { ReactElement, ReactNode, FC, CSSProperties, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Callback } from 'src/redux/types';
import { emptyFunction } from 'src/utils';
import { Input } from '..';
import EllipsisTooltipBaseInput, {
  EllipsisTooltipBaseInputProps,
} from '../EllipsisTooltipBaseInput';
import { InputIcon, InputProps } from '../Input';
import { Box } from '@mui/material';

const baseStyles = {
  overflow: 'hidden',
  textOverflow: 'clip',
  borderStyle: 'border-box',
  fontSize: 14,
};

const DollarInputIcon = (
  <InputIcon
    iconName={'ic_dollar'}
    isIconPositionLeft={true}
    iconComponent={null}
    onIconClick={emptyFunction}
  />
);

const CustomInput: FC<CurrencyInputProps> = ({
  showCustomIcon,
  customIcon = DollarInputIcon,
  customIconPosition = 'left',
  ...props
}) => (
  <Input
    iconPosition={customIconPosition}
    {...(showCustomIcon && { customIcon: customIcon })}
    {...props}
  />
);

const InputCurrency: FC<CurrencyInputProps> = ({
  unFixedDecimalScale = false,
  name,
  value,
  className,
  style,
  customIcon,
  showCustomIcon,
  customIconPosition,
  prefix = '$',
  decimalScale = 2,
  thousandSeparator = true,
  disabled,
  defaultValue,
  textAlign = 'right',
  footer,
  onChange,
  lengthLimited,
  ...props
}) => {
  const [isFixedDecimalScale, setIsFixedDecimalScale] = useState(true);
  const handleChange = (values) => {
    const { value } = values;
    onChange && onChange(name, value);
  };

  const convertCurrencyToString = (currency) => {
    if (!currency && currency !== 0) return '';
    const value = currency.toString();
    return value;
  };

  const checkConditionWhenValueIsDot = (value) => {
    // regex check number string and negative number string

    const regex = /^-?[0-9]*$/;
    return regex.test(value);
  };

  const handleNumber = (value: string) => {
    const [integral, decimal] = value.split('.') || [null, null];
    if (integral === '0' && !decimal) {
      return '0.00';
    }
    if (integral === '0' && decimal) {
      return `0.${decimal}`;
    }
    return integral.slice(0, lengthLimited) + (decimal ? `.${decimal}` : '');
  };

  const handleValueHasLimitedLength = (value) => {
    const valueString = value.toString();
    const [integral] = valueString.split('.') || [];
    if (!!lengthLimited && integral > lengthLimited) {
      return handleNumber(valueString);
    }
    return value;
  };

  return (
    <>
      <CurrencyFormat
        className={className}
        style={{ ...baseStyles, textAlign: textAlign, ...style }}
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={isFixedDecimalScale}
        decimalScale={decimalScale}
        displayType="input"
        prefix={prefix}
        name={name}
        customInput={CustomInput}
        disabled={disabled}
        onValueChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === '.' && !checkConditionWhenValueIsDot(value)) {
            onChange(name, e.key);
          }
        }}
        {...props}
        value={convertCurrencyToString(value)}
        onBlur={function () {
          setIsFixedDecimalScale(true);
          if (!value && value !== 0) return;
          if (value === '.') return onChange(name, '0.00');
          const pre = handleValueHasLimitedLength(value);
          onChange(name, Number(pre).toFixed(decimalScale));
        }}
        onFocus={function () {
          setIsFixedDecimalScale(false);
        }}
      />
      {footer && <Box mt={1}>{footer}</Box>}
    </>
  );
};

const EllipsisTooltipInputCurrency: FC<EllipsisTooltipInputProps> = ({ ...props }) => {
  return (
    <EllipsisTooltipBaseInput {...props}>
      <InputCurrency name={props.name} />
    </EllipsisTooltipBaseInput>
  );
};

export type EllipsisTooltipInputProps = EllipsisTooltipBaseInputProps & CurrencyInputProps;

export type CurrencyInputProps = Omit<CurrencyFormat.Props, 'InputProps'> & {
  InputProps?: InputProps;
} & {
  unFixedDecimalScale?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  name: string;
  className?: string;
  style?: CSSProperties;
  customIcon?: ReactElement;
  showCustomIcon?: boolean;
  customIconPosition?: 'left' | 'right';
  prefix?: string;
  decimalScale?: number;
  thousandSeparator?: string | boolean;
  disabled?: boolean;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  footer?: ReactNode;
  onChange?: Callback;
  lengthLimited?: number;
};

export default InputCurrency;
export { EllipsisTooltipInputCurrency };
