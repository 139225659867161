import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';

export function useTravelRequestCloneDocument(options?: UseMutationOptions<any, Error, any>) {
  const { mutate: travelRequestCloneDocument, isLoading } = useMutation<any, Error, any>({
    mutationFn: (payload: { id: string }) =>
      responseWrapper(apiClient.postTravelRequestCloneDocument, [payload]),
    ...options,
  });

  return {
    travelRequestCloneDocument,
    isLoading,
  };
}
