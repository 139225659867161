import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { MassPrintingOfMonthEndProjectReportResponse, MassPrintingReportParams } from './types';

export function useGetMassPrintingReport(
  options?: UseQueryOptions<
    ApiResponseType<MassPrintingOfMonthEndProjectReportResponse>,
    Error,
    MassPrintingOfMonthEndProjectReportResponse
  > &
    Partial<MassPrintingReportParams>
) {
  const role = RoleService.getCurrentRole();
  const {
    data: massPrintingReport,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetMassPrintingReportReport,
  } = useQuery<
    ApiResponseType<MassPrintingOfMonthEndProjectReportResponse>,
    Error,
    MassPrintingOfMonthEndProjectReportResponse
  >(
    [
      API_QUERIES.MASS_PRINTING_REPORT,
      {
        userId: options.userId,
        userType: options.userType,

        massMonth: options?.massMonth,
        massYear: options?.massYear,

        faIdMass: options?.faIdMass,
        piIdMass: options?.piIdMass,

        code: options?.code,
        fullName: options.fullName,
        role: role,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<MassPrintingOfMonthEndProjectReportResponse>>(
          apiClient.getMassPrintingReport,
          params
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateMassPrintingReport = () =>
    queryClient.invalidateQueries(API_QUERIES.MASS_PRINTING_REPORT);

  return {
    massPrintingReport,
    error,
    isError,
    isLoading,
    onGetMassPrintingReportReport,
    handleInvalidateMassPrintingReport,
  };
}
