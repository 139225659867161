import { Box, SxProps } from '@mui/material';
import cn from 'classnames';
import { COLOR_CODE } from 'src/appConfig/constants';

const SectionLayout: React.FC<Props> = ({
  sx,
  children,
  header,
  footer,
  hasBorderPrint = true,
  hasPaddingPrint = true,
  hasMarginTopPrint = true,
  hasBorderTopPrint = true,
  className,
}) => {
  return (
    <>
      {header && <Box>{header}</Box>}
      <Box
        sx={sx}
        py={1}
        px={2}
        bgcolor={'white'}
        border={COLOR_CODE.DEFAULT_BORDER}
        {...(!header && { mt: 1 })}
        className={cn(
          className,
          { 'print_section-layout': hasPaddingPrint },
          { 'print_section-layout-no-padding': !hasPaddingPrint },
          { 'print_section-layout-no-margin-top': !hasMarginTopPrint },
          { 'print-section-layout-no-border-top': !hasBorderTopPrint },
          {
            'print_section-layout-border': hasBorderPrint,
          }
        )}
      >
        {children}
      </Box>
      {footer}
    </>
  );
};

interface Props {
  sx?: SxProps;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  hasBorderPrint?: boolean;
  className?: string;
  hasPaddingPrint?: boolean;
  hasMarginTopPrint?: boolean;
  hasBorderTopPrint?: boolean;
}

export default SectionLayout;
