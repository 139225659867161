import { useState } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, authResponseWrapper } from 'src/queries/helpers';
import { UrlExport } from './types';

export function useGetUrlExportRCUHBudgetStatusSummary(
  options?: UseMutationOptions<ApiResponseType<{ data: UrlExport }>, Error, any> & {}
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlExportRCUHBudgetStatusSummary, isLoading } = useMutation<
    ApiResponseType<{ data: UrlExport }>,
    Error,
    any
  >({
    mutationFn: (params: any) => {
      return authResponseWrapper<ApiResponseType<{ data: UrlExport }>>(
        apiClient.getUrlExportRCUHBudgetStatusSummaryReport,
        [params]
      );
    },
    ...options,
  });

  return {
    getUrlExportRCUHBudgetStatusSummary,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
