import { Grid, Typography } from '@mui/material';
import CustomTable from 'src/components/CustomTable';
import { CellType } from 'src/components/CustomTable/types';
import { POAuditTrails } from 'src/queries';
import { DateFormatDisplayMinute, getDateDisplay } from 'src/utils';

export const PrintAuditInformation = ({ audits }: { audits: POAuditTrails[] }) => {
  const listItem =
    audits.length > 0
      ? audits.map((audit) => ({
          columns: [
            {
              content: (
                <Typography variant="body2">
                  {getDateDisplay(audit?.date ?? audit?.createdAt, DateFormatDisplayMinute)}
                </Typography>
              ),
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
            {
              content: (
                <Typography variant="body2">
                  {audit.user}
                </Typography>
              ),
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
            {
              content: <Typography variant="body2">{audit?.userType}</Typography>,
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
            {
              content: <Typography variant="body2">{audit?.action}</Typography>,
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
          ],
        }))
      : [
          {
            columns: [],
          },
        ];
  return (
    <Grid
      spacing={2}
      sx={{
        display: 'none',
      }}
      className="print__display"
    >
      <CustomTable.Layout
        bodyList={[
          {
            columns: [
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    Date/Time
                  </Typography>
                ),
                colSpan: 2,
                width: '30%',
                type: CellType.PRINT_CELL,
              },
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    User
                  </Typography>
                ),
                colSpan: 2,
                width: '30%',
                type: CellType.PRINT_CELL,
              },
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    User Type
                  </Typography>
                ),
                colSpan: 2,
                width: '20%',
                type: CellType.PRINT_CELL,
              },
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    Action
                  </Typography>
                ),
                colSpan: 2,
                width: '20%',
                type: CellType.PRINT_CELL,
              },
            ],
          },
          ...(listItem as any),
        ]}
      />
    </Grid>
  );
};
