import { useState } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, authResponseWrapper } from '../../helpers';
import { UrlExport } from './types';

export function useGetUrlExportBudgetStatusReport(
  options?: UseMutationOptions<ApiResponseType<{ data: UrlExport }>, Error, string> & {
    project: string;
  }
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlExportBudgetStatusReport, isLoading } = useMutation<
    ApiResponseType<{ data: UrlExport }>,
    Error,
    string
  >({
    mutationFn: () => {
      return authResponseWrapper<ApiResponseType<{ data: UrlExport }>>(
        apiClient.getUrlExportBudgetStatusReport,
        [{ project: options.project }]
      );
    },
    ...options,
  });

  return {
    getUrlExportBudgetStatusReport,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
