import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { UHReportParams } from 'src/containers/UHReportContainer/types';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { DailyBalanceReportResponse } from './types';

export function useGetDailyBalanceReport(
  options?: UseQueryOptions<
    ApiResponseType<DailyBalanceReportResponse>,
    Error,
    DailyBalanceReportResponse
  > &
    Partial<UHReportParams>
) {
  const role = RoleService.getCurrentRole();
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<ApiResponseType<DailyBalanceReportResponse>, Error, DailyBalanceReportResponse>(
    [API_QUERIES.DAILY_BALANCE_REPORT, { project: options.project, role: role }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<DailyBalanceReportResponse>>(
          apiClient.getDailyBalanceReport,
          params
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateDailyBalanceReport = () =>
    queryClient.invalidateQueries(API_QUERIES.DAILY_BALANCE_REPORT);

  return {
    data,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateDailyBalanceReport,
  };
}
