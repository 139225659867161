import { memo } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Checkbox } from 'src/components/common';
import { CheckboxItemProps } from 'src/components/common/Checkbox';

const FormCheckboxItem = ({ control, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <Checkbox.Item
      label={props.label}
      {...props}
      {...field}
      errorMessage={fieldState.error?.message}
    />
  );
};

type Props<T = any> = UseControllerProps<T> & CheckboxItemProps;

export default memo(FormCheckboxItem);
