import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { StaffListingPayload } from './types';

export function useUpdateStaffListing(options?: UseMutationOptions<any, Error, any>) {
  const { mutate: updateStaffListing, isLoading } = useMutation<any, Error, any>({
    mutationFn: (payload: StaffListingPayload) =>
      responseWrapper(apiClient.updateStaffListing, [payload]),
    ...options,
  });

  return {
    updateStaffListing,
    isLoading,
  };
}
