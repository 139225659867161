import { memo } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { TextareaAutosize } from 'src/components/common';
import { CustomTextareaAutosizeProps } from 'src/components/common/TextareaAutosize';
import './styles.scss';

const FormTextareaAutosize = ({ control, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <TextareaAutosize
      containerClassName="form-textarea-autosize"
      label={props.label}
      {...props}
      {...field}
      errorMessage={fieldState.error?.message}
    />
  );
};

type Props<T = any> = UseControllerProps<T> & CustomTextareaAutosizeProps;

export default memo(FormTextareaAutosize);
