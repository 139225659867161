import { memo } from 'react';
import { UseControllerProps, UseFormSetValue, useController } from 'react-hook-form';
import { InputCurrency } from 'src/components/common';
import { CurrencyInputProps } from 'src/components/common/InputCurrency';

const FormInputCurrency = ({ control, setValue, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <InputCurrency
      label={props.label}
      {...props}
      {...field}
      onChange={setValue}
      errorMessage={fieldState.error?.message}
    />
  );
};

type Props<T = any> = UseControllerProps<T> &
  CurrencyInputProps & {
    setValue: UseFormSetValue<T>;
  };

export default memo(FormInputCurrency);
