import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { PODetailResponse } from './types';

//use case: for get detail data without caching
export function useGetPODetailMutation(
  options?: UseMutationOptions<ApiResponseType<PODetailResponse>, Error, { id: string }>
) {
  const {
    mutate: getPODetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<PODetailResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) => responseWrapper(apiClient.getPODetail, [params]),
    ...options,
  });

  return {
    getPODetailMutation,
    isLoading,
    isSuccess,
  };
}
