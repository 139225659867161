export enum TRAVEL_TABLE_KEY {}

export enum TRAVEL_DOCUMENTS_TYPE {
  TRAVEL_REQUEST = 'travelRequest',
  TRAVEL_COMPLETION = 'travelCompletion',
}

export enum TRAVEL_COMPLETION_WORK_FLOW_STATUS_KEY {
  ALL_TRAVEL_COMPLETION_DOCUMENTS = 'all',
  PENDING_TRAVEL_COMPLETION_DOCUMENTS = 'pending',
  REVIEW_APPROVE_TRAVEL_COMPLETION_DOCUMENTS = 'review',
  APPROVED_TRAVEL_COMPLETION_DOCUMENTS = 'approved',
  OUTSTANDING_TRAVEL_COMPLETION_DOCUMENTS = 'outstanding',
  SEARCH_TRAVEL_COMPLETION_DOCUMENTS = 'all',

  TRAVEL_COMPLETION_ON_REQUEST = 'completiononrequest',
}

export enum TRAVEL_COMPLETION_LIST_QUERY_KEY {
  WORKFLOW_STATUS = 'workflowStatus',
  SORT = 'sort',

  // For form
  REQUEST_NUMBER = 'requestNumber',
  DOCUMENT_NUMBER = 'documentNumber',
  DOCUMENT_TYPE = 'documentType',
  LISTED_PROJECT_NUMBER = 'listedProjectNumber',
  VENDOR_NAME = 'vendorName',
  FA_STAFF_REVIEWER = 'faStaffReviewer',
  PI_NAME = 'piName',
  START_DEPARTURE_DATE = 'startDepartureDate',
  END_ARRIVAL_DATE = 'endArrivalDate',
  MODIFIED_DATE = 'modifiedDate',
  CHECK_NUMBER = 'checkNumber',
  CHECK_DATE = 'checkDate',
  ACCEPTED_DATE = 'acceptedDate',

  // for Params
  START_DEPARTURE_DATE_FROM = 'startDepartureDateFrom',
  START_DEPARTURE_DATE_TO = 'startDepartureDateTo',

  END_ARRIVAL_DATE_FROM = 'endArrivalDateFrom',
  END_ARRIVAL_DATE_TO = 'endArrivalDateTo',

  MODIFIED_START_DATE = 'modifiedStartDate',
  MODIFIED_END_DATE = 'modifiedEndDate',

  CHECK_START_DATE = 'checkStartDate',
  CHECK_END_DATE = 'checkEndDate',

  ACCEPTED_START_DATE = 'acceptedStartDate',
  ACCEPTED_END_DATE = 'acceptedEndDate',

  STATUS = 'status',
  PAYMENT_TYPE = 'paymentType',
}
