import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';

export function useGetVendorRecordById(
  options?: UseQueryOptions<ApiResponseType<any>, Error, any> & {
    code: string;
  }
) {
  const {
    data: vendorRecordDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetVendorRecordById,
  } = useQuery<ApiResponseType<any>, Error, any>(
    [API_QUERIES.VENDORS_RECORDS, { code: options.code }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<any>>(apiClient.getVendorRecordById, params);
      },
      select: getResponseData,
      enabled: false,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateVendorRecord = () =>
    queryClient.invalidateQueries([API_QUERIES.VENDORS_RECORDS, { code: options.code }]);

  return {
    vendorRecordDetail,
    error,
    isError,
    isLoading,
    onGetVendorRecordById,
    handleInvalidateVendorRecord,
  };
}
