import { memo } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Input } from 'src/components/common';
import { InputProps } from 'src/components/common/Input';

const FormInput = ({ control, ...props }: Props) => {
  const { field, fieldState } = useController({ name: props.name, control: control });

  return (
    <Input label={props.label} {...props} {...field} errorMessage={fieldState.error?.message} />
  );
};

type Props<T = any> = UseControllerProps<T> & InputProps;

export default memo(FormInput);
