type UnderlineCharacterProps = {
  text: string;
};

export const UnderlineCharacter = ({ text }: UnderlineCharacterProps) => {
  const handleConvertText = (text: string) => {
    switch (text) {
      case 'Submit to FA':
        return (
          <>
            Su<span style={{ textDecoration: 'underline' }}>b</span>mit to FA
          </>
        );
      case 'Save':
        return (
          <>
            <span style={{ textDecoration: 'underline' }}>S</span>ave
          </>
        );
      case 'Back':
        return (
          <>
            <span style={{ textDecoration: 'underline' }}>B</span>ack
          </>
        );
      case 'Delete':
        return (
          <>
            Dele<span style={{ textDecoration: 'underline' }}>t</span>e
          </>
        );
      case 'Cancel':
        return (
          <>
            <span style={{ textDecoration: 'underline' }}>C</span>ancel
          </>
        );
      case 'Approve':
        return (
          <>
            A<span style={{ textDecoration: 'underline' }}>p</span>prove
          </>
        );
      case 'Disapprove':
        return (
          <>
            D<span style={{ textDecoration: 'underline' }}>i</span>sapprove
          </>
        );
      case 'Request More Info':
        return (
          <>
            <span style={{ textDecoration: 'underline' }}>R</span>equest More Info
          </>
        );
      case 'Clone Document':
        return (
          <>
            Cl<span style={{ textDecoration: 'underline' }}>o</span>ne Document
          </>
        );
      case 'Vendor Print Mode':
        return (
          <>
            Vendor Print <span style={{ textDecoration: 'underline' }}>M</span>ode
          </>
        );
      case 'Mark as Printed':
        return (
          <>
            Mark as <span style={{ textDecoration: 'underline' }}>P</span>rinted
          </>
        );
      case 'Purchase Order View Mode':
        return (
          <>
            Purchase Order View <span style={{ textDecoration: 'underline' }}>M</span>ode
          </>
        );
      case 'Apply':
        return (
          <>
            <span style={{ textDecoration: 'underline' }}>A</span>pply
          </>
        );
      case 'Search':
        return (
          <>
            <span style={{ textDecoration: 'underline' }}>S</span>earch
          </>
        );
      case 'Refresh':
        return (
          <>
            <span style={{ textDecoration: 'underline' }}>R</span>efresh
          </>
        );
      case 'Add User':
        return (
          <>
            Add <span style={{ textDecoration: 'underline' }}>U</span>ser
          </>
        );
      case 'Add Vendor':
        return (
          <>
            Add <span style={{ textDecoration: 'underline' }}>V</span>endor
          </>
        );
      case 'Submit':
        return (
          <>
            Su<span style={{ textDecoration: 'underline' }}>b</span>mit
          </>
        );
    }
  };
  return <>{handleConvertText(text)}</>;
};
