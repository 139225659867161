import { memo, useEffect } from 'react';
import { useGetNetworkStatus } from 'src/queries';

const NetworkStatusContainer: React.FC<Props> = () => {
  const { getNetworkStatus } = useGetNetworkStatus();

  useEffect(() => {
    getNetworkStatus({});

    const interval = setInterval(() => {
      getNetworkStatus({});
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

type Props = {};

export default memo(NetworkStatusContainer);
