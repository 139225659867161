import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { PersonalAutomobileResponse } from './types';

//use case: for get detail data without caching
export function useGetPersonalAutoDetailMutation(
  options?: UseMutationOptions<ApiResponseType<PersonalAutomobileResponse>, Error, { id: string }>
) {
  const {
    mutate: getPersonalAutoDetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<PersonalAutomobileResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) =>
      responseWrapper(apiClient.getPersonalAutoDetail, [params]),
    ...options,
  });

  return {
    getPersonalAutoDetailMutation,
    isLoading,
    isSuccess,
  };
}
