export enum CheckPrintingAction {
  GENERATE_REGISTERS = 'generate_registers',
  DONE = 'done',
}

export enum CheckPrintingStatus {
  FINAL = 'Final',
  IN_PROGRESS = 'In Progress',
}

export interface CurrentCheckPrinting {
  id: string;
  externalId: number;
  createdAt: string;
  updatedAt: string;
  checkCount: number;
  createdDate: string;
  checkGrandTotal: number;
  status: string;
  step: number;
  firstCheckNumber: string;
  lastCheckNumber: string;
  firstGoodCheckNumber: string;
  lastGoodCheckNumber: string;
  individualVoidedChecks: string;
  allVoidedChecks: string;
  batchId: string;
  registersCreated: boolean;
  checksReady: boolean;
  checksFileName: string;
  checksMICR: ChecksMicr[];
}

export interface PatchCurrentCheckPrintingResponse {
  checkPrinting: CheckPrinting;
  checkRegister: CheckRegister;
}

interface CheckPrinting {
  id: string;
  externalId: number;
  createdAt: string;
  updatedAt: string;
  checkCount: number;
  createdDate: string;
  checkGrandTotal: string;
  status: CheckPrintingStatus;
  step: number;
  firstCheckNumber: string;
  lastCheckNumber: string;
  firstGoodCheckNumber: string;
  lastGoodCheckNumber: string;
  individualVoidedChecks: string;
  allVoidedChecks: string;
  batchId: string;
  registersCreated: boolean;
  checksReady: boolean;
  checksFileName: string;
  checksMICR: ChecksMicr[];
  batch: Batch[];
}

interface CheckRegister {
  id: string;
  checkPrintingId: string;
  externalId: number;
  createdAt: string;
  updatedAt: string;
  date: string;
  createdDate: string;
  grandTotal: number;
  returnedTotal: number;
  mailedTotal: number;
  totalNumberOfChecks: number;
  startingCheckNumber: string;
  endingCheckNumber: string;
  batchId: string;
  return2Processed: true;
  versionNumber: number;
  checkGrandTotal: number;
  nvpGrandTotal: number;
  achTotal: number;
  cardTotal: number;
  nvpTotal: number;
  paymentsTotal: number;
}

interface ChecksMicr {
  checkNumber: string;
  checkDate: string;
  checkCode: string;
  paymentRequestNumber: string;
  dollar: string;
  cent: string;
  checkAmount: string;
  vendorName: string;
  vendorOffice: string;
  vendorStreetAddress: string;
  vendorCityStateZip: string;
  foreignAddress: string;
  remittanceTotal: string;
  inquiry: string;
  attention: string;
  foCode: string;
  phoneNumber: string;
  remitCheckNumber: string;
  invoiceNumber: string;
  customerAccountComment: string;
  poNumber: string;
  remittanceLineAmount: string;
}

interface Batch {
  id: string;
  startingCheckNumber: string;
  endingCheckNumber: string;
  checkFileFilled: string;
  createdMICRFile: string;
  createdMICRFileTimestamp: string;
  createdCheckRegisters: string;
  createdCheckRegistersTimestamp: string;
  checkPrintingFinished: string;
  checkPrintingFinishedTimestamp: string;
  receivedReturn2File: string;
  updatedPaymentsFile: string;
  paymentsUpdated: string;
  paymentsUpdatedTimestamp: string;
}
