import { useRef } from 'react';
import { Callback } from 'src/redux/types';
import { getRandomId } from 'src/utils';
// import { InputMask } from '..';
import { Input } from 'src/components/common';
import Element from '../Element';
import EllipsisTooltipBaseInput, {
  EllipsisTooltipBaseInputProps,
} from '../EllipsisTooltipBaseInput';
import View from '../View';

const InputUSPhone: React.FC<Props> = ({
  label = '',
  errorMessage,
  containerClassName = '',
  name,
  required = false,
  disabled,
  maxLength,
  onChange,
  ...props
}) => {
  const id = useRef(`input-${getRandomId()}`);

  const handleChange = (e) => {
    const value = e.target.value;

    onChange(name, value);
  };

  // For change style of phone input, follow:
  // https://catamphetamine.gitlab.io/react-phone-number-input/
  return (
    <Element id={id.current}>
      <View className="cmp-phoneinput">
        <Input
          // style={{
          //   height: '35px',
          // }}
          name={name}
          // mask="999 999 9999"
          onChange={handleChange}
          disabled={disabled}
          errorMessage={errorMessage}
          label={label}
          maxLength={maxLength}
          className={containerClassName}
          required={required}
          {...props}
        />
      </View>
    </Element>
  );
};

const EllipsisTooltipInputPhone: React.FC<EllipsisTooltipInputProps> = ({ ...props }) => {
  return (
    <EllipsisTooltipBaseInput {...props}>
      <InputUSPhone name={props.name} onChange={props.onChange} />
    </EllipsisTooltipBaseInput>
  );
};

export type EllipsisTooltipInputProps = EllipsisTooltipBaseInputProps & Props;

type Props = {
  label?: React.ReactNode;
  errorMessage?: string;
  containerClassName?: string;
  name: string;
  icon?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  onChange: Callback;
};

export default InputUSPhone;
export { EllipsisTooltipInputPhone };
