import { UseQueryOptions, useQuery } from 'react-query';
import { UHReportParams } from 'src/containers/UHReportContainer/types';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { KFSContinuationAccountTransactionsReportResponse } from './types';

export function useGetKFSContinuationAccountTransactionsReport(
  options?: UseQueryOptions<
    ApiResponseType<KFSContinuationAccountTransactionsReportResponse>,
    Error,
    KFSContinuationAccountTransactionsReportResponse
  > &
    Partial<UHReportParams>
) {
  const role = RoleService.getCurrentRole();
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<
    ApiResponseType<KFSContinuationAccountTransactionsReportResponse>,
    Error,
    KFSContinuationAccountTransactionsReportResponse
  >(
    [
      API_QUERIES.KFS_CONTINUATION_ACCOUNT_TRANSACTIONS_REPORT,
      {
        project: options.project,
        transDateStart: options.transDateStart,
        transDateEnd: options.transDateEnd,
        role: role,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<KFSContinuationAccountTransactionsReportResponse>>(
          apiClient.getKFSContinuationAccountTransactionsReport,
          params
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  return {
    data,
    error,
    isError,
    isLoading,
    refetch,
  };
}
