import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { TravelCompletionDetailResponse, UpsertTravelCompletionPayload } from './types';

export function useCreateTravelCompletion(
  options?: UseMutationOptions<
    ApiResponseType<TravelCompletionDetailResponse>,
    Error,
    UpsertTravelCompletionPayload
  >
) {
  const {
    mutate: createTravelCompletion,
    data,
    isLoading,
    isSuccess,
  } = useMutation<
    ApiResponseType<TravelCompletionDetailResponse>,
    Error,
    UpsertTravelCompletionPayload
  >({
    mutationFn: (payload: UpsertTravelCompletionPayload) =>
      responseWrapper(apiClient.createTravelCompletion, [payload]),
    ...options,
  });

  return {
    createTravelCompletion,
    data,
    isLoading,
    isSuccess,
  };
}
