import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { INotificationRes } from './types';

export function useGetNotification(
  options?: UseQueryOptions<ApiResponseType<INotificationRes>, Error, INotificationRes> & {
    isCurrent: boolean;
  }
) {
  const {
    data: notification,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetNotification,
  } = useQuery<ApiResponseType<INotificationRes>, Error, INotificationRes>(
    [API_QUERIES.NOTIFICATION, { isCurrent: options.isCurrent }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<INotificationRes>>(
          apiClient.getNotification,
          params
        );
      },
      select: getResponseData,
      enabled: options?.isCurrent,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidatePOPaymentDetail = (id: string) =>
    queryClient.invalidateQueries([API_QUERIES.NOTIFICATION, { id }]);

  return {
    notification,
    error,
    isError,
    isLoading,
    onGetNotification,
    handleInvalidatePOPaymentDetail,
  };
}
