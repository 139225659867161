import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { CurrentProjectAssignmentParams } from '../UHProjectFiscal/types';
import { CurrentProjectAssignmentReportResponse } from './types';

export function useGetCurrentProjectAssignmentReport(
  options?: UseQueryOptions<
    ApiResponseType<CurrentProjectAssignmentReportResponse>,
    Error,
    CurrentProjectAssignmentReportResponse
  > &
    Partial<CurrentProjectAssignmentParams>
) {
  const role = RoleService.getCurrentRole();
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<
    ApiResponseType<CurrentProjectAssignmentReportResponse>,
    Error,
    CurrentProjectAssignmentReportResponse
  >(
    [
      API_QUERIES.RCUH_CURRENT_PROJECT_ASSIGNMENT,
      {
        userId: options.userId,
        code: options.code,
        roleCode: options?.roleCode,
        piId: options.piId,
        faId: options.faId,
        fullName: options.fullName,
        role: role,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<CurrentProjectAssignmentReportResponse>>(
          apiClient.getRCUHCurrentProjectAssignmentReport,
          params
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleCurrentProjectAssignmentReport = () =>
    queryClient.invalidateQueries(API_QUERIES.RCUH_CURRENT_PROJECT_ASSIGNMENT);

  return {
    data,
    error,
    isError,
    isLoading,
    refetch,
    handleCurrentProjectAssignmentReport,
  };
}
