import { Box, SxProps } from '@mui/material';
import { FieldInputProps } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'src/components/common';
import { SelectOption } from 'src/components/common/Select';
import { FinancialProject, useGetProfileProjects } from 'src/queries';
import { getFinancialProjectNumberOptions } from '../SearchProjectNumber/helpers';
import { debounce } from 'lodash';
import { isEmpty } from 'src/validations';
import './styles.scss';
import classNames from 'classnames';
import { ProjectColumnOrder } from '../types';
import { ORDER } from 'src/appConfig/constants';

const SelectProjectNumberReport = ({
  errorMessage,
  fieldProps,
  sx,
  label = '',
  maxLength = 7,
  menuIsOpen,
  required = false,
  isClearable = false,
  isSearchableTerminatedProject = false,
  isSearchableInactiveProject = false,
  order = ORDER.ASC,
  sort = ProjectColumnOrder.NUMBER,
  setFieldTouched,
  onChange,
  onInputChange,
}: Props) => {
  const { value, name } = fieldProps;
  const selectedProjectNumber = typeof value === 'string' ? value : value?.number;
  const [inputValue, setInputValue] = useState<string>(selectedProjectNumber);
  const [closeMenuOnSelect, setCloseMenuOnSelect] = useState<boolean>(false);
  //for handle case user use arrow key to select option
  const [isSwitchingSearch, setIsSwitchingSearch] = useState<boolean>(false);

  const handleOpenMenu = () => {
    if (menuIsOpen === false) return false;
    return (!isLoadingSearchProjects && menuIsOpen) || closeMenuOnSelect;
  };

  //always keep search input
  //show data of select box is state of search input (not value of select)
  useEffect(() => {
    setInputValue(selectedProjectNumber);
  }, [selectedProjectNumber]);

  const {
    profileProjects,
    setParams: setParamsSearchProject,
    isLoading: isLoadingSearchProjects,
  } = useGetProfileProjects();

  const filteredProjectNumberOptions = useMemo(() => {
    return getFinancialProjectNumberOptions({
      projects: profileProjects,
    });
  }, [profileProjects]);

  // Debouncing search projects inputs
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchProjectsInput = useCallback(
    debounce((value: string) => {
      setParamsSearchProject({
        search: value,
        includeTerminated: !isEmpty(value) && isSearchableTerminatedProject,
        includeInactive: !isEmpty(value) && isSearchableInactiveProject,
        sort,
        order,
      });
    }, 800),
    []
  );

  useEffect(() => {
    debounceSearchProjectsInput(inputValue);
  }, [debounceSearchProjectsInput, inputValue]);

  return (
    <Box sx={sx}>
      <Select
        {...fieldProps}
        errorMessage={errorMessage}
        onBlur={setFieldTouched}
        label={label}
        placeholder={'Search'}
        options={inputValue ? filteredProjectNumberOptions : []}
        isLoading={isLoadingSearchProjects}
        inputValue={inputValue || ''}
        onInputChange={(val, meta) => {
          setIsSwitchingSearch(false);
          if (['input-blur', 'menu-close'].includes(meta.action)) {
            setCloseMenuOnSelect(false);
          }
          if (['input-change', 'input-select', 'set-value'].includes(meta.action)) {
            setInputValue(val);
            onInputChange(name, val);
          }
        }}
        required={required}
        defaultInputValue={selectedProjectNumber} //first mounted with data get from PO response
        getOptionLabel={(option: SelectOption<FinancialProject>) => {
          return option.value?.number;
        }}
        customSelectedOptionValue={
          filteredProjectNumberOptions.find(
            (option: SelectOption<FinancialProject>) => option.value?.number === value
          ) || null
        }
        filterOption={(_option, _inputValue) => {
          return true; //ignore default filter option by label
        }}
        menuStyle={{
          width: '760px',
        }}
        className={classNames('cmp-select__input')}
        hideSearchIcon
        isClearable={isClearable}
        onChange={onChange}
        optionWithSubLabel
        hideDropdownIndicator
        searchMaxLength={maxLength}
        menuIsOpen={handleOpenMenu()}
        onKeyDown={(e) => {
          setCloseMenuOnSelect(true);
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            setIsSwitchingSearch(true);
            return;
          }

          if (e.key === 'Enter' && !isSwitchingSearch) {
            e.preventDefault();
            setCloseMenuOnSelect(false);
            return;
          }
        }}
      />
    </Box>
  );
};

type Props = {
  errorMessage: string;
  fieldProps: FieldInputProps<FinancialProject | string>;
  disabled?: boolean;
  sx?: SxProps;
  isClearable?: boolean;
  required?: boolean;
  label?: string;
  maxLength?: number;
  menuIsOpen?: boolean;
  isSearchableTerminatedProject?: boolean;
  isSearchableInactiveProject?: boolean;
  order?: ORDER;
  sort?: ProjectColumnOrder;
  setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
  onInputChange?: (name, value) => void;
  onChange: (name, value) => void;
};

export default SelectProjectNumberReport;
