import apisauce from 'apisauce';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { delay } from 'lodash';
import appConfig from 'src/appConfig';
import {
  CHECK_REGISTER_DETAIL_VIEW,
  GetFinancialProjectsParams,
  GetTokenDelegationPayload,
  PostPOChangeTypePayload,
  UpdateGlobalSettingPayload,
  UpdatePOPaymentPayload,
  UpdateProfilePayload,
  UpsertTravelCompletionPayload,
  UpsertTravelRequestPayload,
} from 'src/queries';
import { GetPresignedPayload, UploadFilePayload } from 'src/queries/File/types';
import { UpsertAuthorizationPayload } from 'src/queries/NonPOPayment/AuthorizationForPayment/types';
import { UpsertNonEmployeeTravelPayload } from 'src/queries/NonPOPayment/NonEmployeeTravel/types';
import { UpsertPettyCashPayload } from 'src/queries/NonPOPayment/PettyCash/types';
import { GetProfileProjectsParams } from 'src/queries/Projects/useGetProfileProjects';
import {
  AddPoAttachmentPayload,
  DeletePoAttachmentPayload,
  GetPresignedPOPayload,
  GetPresignedPoAttachmentDownloadUrl,
  UpsertPOPayload,
} from 'src/queries/PurchaseOrders';
import { StaffListingPayload } from 'src/queries/StaffListing';
import { SearchQuoteParams } from 'src/queries/SuperQuotes/types';
import {
  ChangePasswordPayload,
  CompleteNewPasswordPayload,
  ConfirmPasswordPayload,
  ConfirmSignInPayload,
  ForgotPasswordPayload,
  SignInPayload,
  SubmitForgotPasswordPayload,
} from 'src/queries/UAM/types';
import {
  AddUserPayload,
  UpdateUserLastPasswordChangedParams,
  UpdateUserPayload,
  User,
} from 'src/queries/Users/types';
import {
  SearchVendorsParams,
  VendorRecordPayload,
  VendorRegistrationAttachment,
  VendorRegistrationPayload,
} from 'src/queries/Vendors';
import { GetPropertiesParams } from 'src/queries/helpers';
import { getCurrentTimeZone, newCancelToken, stringify } from 'src/utils';
import {
  DelegationKeyService,
  RoleService,
  Toastify,
  TokenService,
  XApiKeyService,
  ZipCodeService,
} from '.';
import { PersonalAutomobilePayload } from '../queries/NonPOPayment/PersonalAuto/types';

axios.defaults.withCredentials = true;

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) => {
    return TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = 'Bearer ' + token;

        const delegationKey = DelegationKeyService.getDelegationKey();
        const currentRoleKey = RoleService.getCurrentRole();

        if (delegationKey) {
          config.headers['x-delegation-token'] = delegationKey;
        }
        if (currentRoleKey) {
          config.headers['x-active-role'] = currentRoleKey;
        }

        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });

  api.axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      //handle 401 error
      //force refresh token & retry to calling api
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        await TokenService.forceRefreshToken();

        //re-call api here will trigger the interception.request again => interceptors.response
        const response = await api.axiosInstance(originalRequest);

        return response;
      } else if (error.response.status === 401 && originalRequest._retry) {
        Toastify.error('Token cannot be refreshed. Please try to login again.');

        delay(() => {
          signOut();
        }, 2000);

        return;
      }

      //handle other errors code here
      //

      return Promise.reject(error);
    }
  );

  const getRoot = () => api.get('');

  // ====================== Auth ======================
  const signIn = (body: SignInPayload) => Auth.signIn(body.username, body.password);

  const signOut = () => {
    TokenService.clearToken();
    DelegationKeyService.clearDelegationKey();
    RoleService.clearCurrentRole();

    return Auth.signOut();
  };

  const forgotPassword = (body: ForgotPasswordPayload) => Auth.forgotPassword(body.username);

  const submitForgotPassword = (body: SubmitForgotPasswordPayload) =>
    Auth.forgotPasswordSubmit(body.email, body.token, body.password);

  const changePassword = (body: ChangePasswordPayload) => {
    return Auth.changePassword(body.user, body.currentPassword, body.newPassword);
  };

  const confirmSignIn = (body: ConfirmSignInPayload) =>
    Auth.sendCustomChallengeAnswer(body.user, body.code);

  const confirmPassword = async (password: ConfirmPasswordPayload) => {
    return await Auth.currentAuthenticatedUser().then((user) =>
      Auth.signIn({
        username: user.username,
        password: password.password,
      })
    );
  };

  const completeNewPassword = (body: CompleteNewPasswordPayload) =>
    Auth.completeNewPassword(body.user, body.password, body.requiredAttributes);

  // ================== Miscellaneous ===============
  const getNetworkStatus = () => {
    return api.get('/financial-svc/v1/network/status', {}, newCancelToken());
  };

  // ================== Miscellaneous: Staff Listing ===============
  const getStaffListing = () => {
    return api.get('/financial-svc/v1/staff-listing', {}, newCancelToken());
  };

  const updateStaffListing = (body: StaffListingPayload) =>
    api.put(`/financial-svc/v1/staff-listing`, body, newCancelToken());

  // ====================== Profile ======================
  const getMyProfile = () => api.get('/account-svc/v1/me', {}, newCancelToken());

  const updateMyProfile = (body: UpdateProfilePayload) =>
    api.put(`/account-svc/v1/me`, body, newCancelToken());

  const updateCurrentRoleMyProfile = (body: { roleName: string }) =>
    api.put(`/account-svc/v1/me/current-role`, body, newCancelToken());

  const updateProfilePasswordResetRequired = () =>
    api.put(`/account-svc/v1/me/password-reset-required`, {}, newCancelToken());

  const getDelegationAccesses = () => {
    return api.get('/account-svc/v1/delegation-accesses', {}, newCancelToken());
  };

  const getTokenDelegation = (body: GetTokenDelegationPayload) => {
    return api.post(
      '/account-svc/v1/me/switch-user',
      {
        accessId: body.accessId,
      },
      newCancelToken()
    );
  };

  // ====================== Content ======================
  const getContents = () => api.get('/account-svc/v1/contents', {}, newCancelToken());
  const getCityStateByZipCode = (params) => ZipCodeService.getPopulatedCityStateFromZipCode(params);
  const getPICodes = () => api.get('/financial-svc/v1/projects/pi-codes', {}, newCancelToken());
  const getFACodes = () => api.get('/financial-svc/v1/projects/fa-codes', {}, newCancelToken());

  // ====================== File ======================
  const getPresignedUserServiceUrl = (params: GetPresignedPayload) => {
    return api.get('/file-svc/v1/presigned-upload-url', params, newCancelToken());
  };
  const uploadFile = (body: UploadFilePayload) => axios.put(body.url, body.fileData);

  const getDecodeUserServiceUrl = (params: { filePath: string }) =>
    api.get('/file-svc/v1/presigned-download-url', params, newCancelToken());

  const uploadFileWithProgress = (body: UploadFilePayload) =>
    axios.put(body.url, body.fileData, {
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);
        body.setProgress(percentageProgress);
      },
    });

  // ====================== Permissions ======================
  const getMyPermissions = () => api.get('/account-svc/v1/permissions/me', {}, newCancelToken());

  const getPermissionCu = () => {
    return api.get('/account-svc/v1/permissions/cu', {}, newCancelToken());
  };

  // ====================== Users Management ======================
  const getAllUsers = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users?${queryString}`, {}, newCancelToken());
  };

  const getUser = (params: { id: User['id'] }) => {
    return api.get(`/account-svc/v1/users/${params.id}`, {}, newCancelToken());
  };

  const createUser = (payload: AddUserPayload) => {
    return api.post(
      `/account-svc/v1/users`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateUser = (payload: UpdateUserPayload) => {
    const url = `/account-svc/v1/users/${payload.id}`;
    payload && delete payload.id;
    return api.put(
      url,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteUser = (userId: User['id']) => {
    return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
  };

  const searchUsers = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users/search/delegates?${queryString}`, {}, newCancelToken());
  };

  const getUrlExportUsers = () => {
    return api.get('/account-svc/v1/users/export', {}, newCancelToken());
  };

  const updateUserLastPasswordChanged = (payload: UpdateUserLastPasswordChangedParams) => {
    const url = `/account-svc/v1/users/pass/${payload.username}`;
    const localXApiKey = XApiKeyService.getApiKey();

    const options = {
      headers: {
        ...(localXApiKey && {
          'X-API-KEY': localXApiKey,
        }),
      },
    };

    return api.put(url, undefined, options);
  };

  // ====================== Projects ======================
  const getFinancialProjects = (params: GetFinancialProjectsParams) => {
    params && delete params.sort;

    const queryString = stringify(params, ['codes']);
    return api.get(`/financial-svc/v1/projects?${queryString}`, {}, newCancelToken());
  };

  const getProfileProjects = (params: GetProfileProjectsParams) => {
    const queryString = stringify(params, ['codes']);
    return api.get(`/financial-svc/v1/projects/me?${queryString}`, {}, newCancelToken());
  };

  // ====================== Vendors ======================
  const getAllVendors = (params: GetPropertiesParams) => {
    const queryString = stringify({ ...params, timezone: getCurrentTimeZone() });
    return api.get(`/financial-svc/v1/vendors?${queryString}`, {}, newCancelToken());
  };

  const createVendorsRecord = (payload: VendorRecordPayload) => {
    return api.post(`/financial-svc/v1/vendors`, { ...payload }, newCancelToken());
  };

  const getVendorRecordById = (params: { code: string }) => {
    return api.get(`/financial-svc/v1/vendors/code/${params.code}`, {}, newCancelToken());
  };

  const updateVendorRecord = (payload: VendorRecordPayload) => {
    return api.put(`/financial-svc/v1/vendors/${payload.id}`, { ...payload }, newCancelToken());
  };

  const searchVendors = (params: SearchVendorsParams & GetPropertiesParams) => {
    const { timeStamp, ...rest } = params;
    const queryString = stringify(rest);
    return api.get(`/financial-svc/v1/vendors/search?${queryString}`, {}, newCancelToken());
  };

  const createVendorRegistration = () => {
    return api.post(`/financial-svc/v1/vendor-registration`, {}, newCancelToken());
  };

  const updateVendorRegistration = (payload: VendorRegistrationPayload) => {
    return api.put(
      `/financial-svc/v1/vendor-registration/${payload.id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getVendorRegistrationById = (params: { code: string }) => {
    return api.get(
      `/financial-svc/v1/vendor-registration/code/${params.code}`,
      {},
      newCancelToken()
    );
  };

  const getVendorRegistrationFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/vendor-registration/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getVendorRegistrationFileAttachmentPresignedDownloadUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/vendor-registration/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const addVendorRegistrationAttachment = (payload: VendorRegistrationAttachment) => {
    return api.put(
      `/financial-svc/v1/vendor-registration/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  const deleteVendorRegistrationAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/vendor-registration/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  // ====================== Super Quotes ======================
  const searchSuperQuotes = (params: SearchQuoteParams & GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/financial-svc/v1/super-quotes/numbers?${queryString}`, {}, newCancelToken());
  };

  const importSuperQuotes = (params: { bidId: string }) => {
    const { bidId = '' } = params || {};
    return api.get(`/financial-svc/v1/super-quotes/quote/${bidId}`, {}, newCancelToken());
  };

  // ====================== PO ======================
  const getPODetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/purchase-orders/${params.id}`, {}, newCancelToken());
  };

  const createPO = (payload: UpsertPOPayload) => {
    return api.post(
      `/financial-svc/v1/purchase-orders?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updatePO = (payload: UpsertPOPayload) => {
    return api.put(
      `/financial-svc/v1/purchase-orders/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deletePO = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/purchase-orders/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  const getPoFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/purchase-orders/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getPoFileAttachmentPresignedDownloadUrl = (params: GetPresignedPoAttachmentDownloadUrl) => {
    return api.get(
      `/financial-svc/v1/purchase-orders/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const addPoAttachment = (payload: AddPoAttachmentPayload) => {
    return api.put(
      `/financial-svc/v1/purchase-orders/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  const deletePOAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/purchase-orders/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  const postPOCloneDocument = (params: any) => {
    return api.post(`/financial-svc/v1/purchase-orders/${params.id}/clone`, {}, newCancelToken());
  };

  // ====================== PO Change ======================
  const createPOChange = (payload: PostPOChangeTypePayload) => {
    return api.post(
      `/financial-svc/v1/purchase-orders/${payload.poId}/po-change`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  // ====================== PO Payment ======================
  const getPOPaymentDetail = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/po-payments/${params.id}`);
  };

  const getPOPaymentRemainingBalance = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/po-payments/${params.id}/remaining-balance`);
  };

  const createPOPayment = (params: { id: string }) => {
    return api.post(
      `/financial-svc/v1/purchase-orders/${params.id}/po-payment`,
      {},
      newCancelToken()
    );
  };

  const updatePOPayment = (payload: UpdatePOPaymentPayload) => {
    return api.put(
      `/financial-svc/v1/po-payments/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deletePOPayment = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/po-payments/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  // ====================== Authorization for Payment ======================
  const postAuthorizationPaymentCloneDocument = (params: any) => {
    return api.post(
      `/financial-svc/v1/authorization-payments/${params.id}/clone`,
      {},
      newCancelToken()
    );
  };

  const createAuthorizationPayment = (payload: UpsertAuthorizationPayload) => {
    return api.post(
      `/financial-svc/v1/authorization-payments?action=${payload.action}`,
      { ...payload },
      newCancelToken()
    );
  };

  const deleteAuthorizationPaymentsAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/authorization-payments/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  const deleteAuthorizationPayment = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/authorization-payments/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  const getAuthorizationPaymentDetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/authorization-payments/${params.id}`, {}, newCancelToken());
  };

  const updateAuthorizationPayment = (payload: UpsertAuthorizationPayload) => {
    return api.put(
      `/financial-svc/v1/authorization-payments/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getAuthorizationPaymentFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/authorization-payments/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getAuthorizationPaymentFileAttachmentPresignedDownloadUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/authorization-payments/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const getAuthorizationPaymentFinalPdf = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/authorization-payments/${params.id}/final-pdf`);
  };

  const addAuthorizationPaymentAttachment = (payload: AddPoAttachmentPayload) => {
    return api.put(
      `/financial-svc/v1/authorization-payments/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  // ====================== Non Employee Travel ======================
  const getNonEmployeeTravelDetail = (params: { id: string }) => {
    return api.get(
      `/financial-svc/v1/non-employee-travel-payments/${params.id}`,
      {},
      newCancelToken()
    );
  };

  const createNonEmployeeTravel = (payload: UpsertNonEmployeeTravelPayload) => {
    return api.post(
      `/financial-svc/v1/non-employee-travel-payments?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateNonEmployeeTravel = (payload: UpsertNonEmployeeTravelPayload) => {
    return api.put(
      `/financial-svc/v1/non-employee-travel-payments/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteNonEmployeeTravel = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/non-employee-travel-payments/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  const getNonEmployeeTravelFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/non-employee-travel-payments/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getNonEmployeeTravelFileAttachmentPresignedDownloadUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/non-employee-travel-payments/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const addNonEmployeeTravelAttachment = (payload: AddPoAttachmentPayload) => {
    return api.put(
      `/financial-svc/v1/non-employee-travel-payments/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  const deleteNonEmployeeTravelAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/non-employee-travel-payments/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  const postNonEmployeeTravelCloneDocument = (params: any) => {
    return api.post(
      `/financial-svc/v1/non-employee-travel-payments/${params.id}/clone`,
      {},
      newCancelToken()
    );
  };

  const getFinalPdfNonEmployeeTravel = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/non-employee-travel-payments/${params.id}/final-pdf`);
  };

  // ====================== Personal Automobile Mileage Voucher  ======================
  const createPersonalAutoCloneDocument = (params: any) => {
    return api.post(
      `/financial-svc/v1/personal-auto-payments/${params.id}/clone`,
      {},
      newCancelToken()
    );
  };

  const createPersonalAutomobile = (payload: PersonalAutomobilePayload) => {
    return api.post(
      `/financial-svc/v1/personal-auto-payments?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getPersonalAutomobileFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/personal-auto-payments/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getPersonalAutomobileFileAttachmentPresignedDownloadUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/personal-auto-payments/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const addPersonalAutoAttachment = (payload: AddPoAttachmentPayload) => {
    return api.put(
      `/financial-svc/v1/personal-auto-payments/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  const deletePersonalAutoAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/personal-auto-payments/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  const getPersonalAutoDetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/personal-auto-payments/${params.id}`, {}, newCancelToken());
  };

  const updatePersonalAutomobile = (payload: PersonalAutomobilePayload) => {
    return api.put(
      `/financial-svc/v1/personal-auto-payments/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getPersonalAutoFinalPDF = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/personal-auto-payments/${params.id}/final-pdf`);
  };

  const deletePersonalAuto = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/personal-auto-payments/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  // ====================== Petty Cash ======================
  const getPettyCashDetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/petty-cash-payments/${params.id}`, {}, newCancelToken());
  };

  const createPettyCash = (payload: UpsertPettyCashPayload) => {
    return api.post(
      `/financial-svc/v1/petty-cash-payments?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updatePettyCash = (payload: UpsertPettyCashPayload) => {
    return api.put(
      `/financial-svc/v1/petty-cash-payments/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deletePettyCash = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/petty-cash-payments/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  const getPettyCashFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/petty-cash-payments/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getPettyCashFileAttachmentPresignedDownloadUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/petty-cash-payments/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const addPettyCashAttachment = (payload: AddPoAttachmentPayload) => {
    return api.put(
      `/financial-svc/v1/petty-cash-payments/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  const deletePettyCashAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/petty-cash-payments/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  const postPettyCashCloneDocument = (params: any) => {
    return api.post(
      `/financial-svc/v1/petty-cash-payments/${params.id}/clone`,
      {},
      newCancelToken()
    );
  };

  const getFinalPdfPettyCashPayment = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/petty-cash-payments/${params.id}/final-pdf`);
  };

  // ====================== Reimbursement For Cash  ======================
  const getReimbursementForCashDetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/reimbursements/${params.id}`, {}, newCancelToken());
  };

  const getReimbursementForCashFileAttachmentPresignedUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/reimbursements/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const getFinalPdfReimbursement = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/reimbursements/${params.id}/final-pdf`);
  };

  // ====================== Multi Intra State Travel  ======================
  const getMultiIntraStateTravelDetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/multi-travels/${params.id}`, {}, newCancelToken());
  };

  const getMultiIntraStateTravelFileAttachmentPresignedUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/multi-travels/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const getFinalPdfMultiIntraStateTravel = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/multi-travels/${params.id}/final-pdf`);
  };

  // ====================== Travel Request ======================
  const getTravelRequestDetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/travel-requests/${params.id}`, {}, newCancelToken());
  };

  const createTravelRequest = (payload: UpsertTravelRequestPayload) => {
    return api.post(
      `/financial-svc/v1/travel-requests?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateTravelRequest = (payload: UpsertTravelRequestPayload) => {
    return api.put(
      `/financial-svc/v1/travel-requests/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteTravelRequest = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/travel-requests/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  const getTravelRequestFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/travel-requests/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getTravelRequestFileAttachmentPresignedDownloadUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/travel-requests/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const addTravelRequestAttachment = (payload: AddPoAttachmentPayload) => {
    return api.put(
      `/financial-svc/v1/travel-requests/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  const deleteTravelRequestAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/travel-requests/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  const postTravelRequestCloneDocument = (params: any) => {
    return api.post(`/financial-svc/v1/travel-requests/${params.id}/clone`, {}, newCancelToken());
  };

  const getFinalPdfTravelRequest = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/travel-requests/${params.id}/final-pdf`);
  };

  // ====================== Travel Completion ======================
  const getTravelCompletionDetail = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/travel-completions/${params.id}`, {}, newCancelToken());
  };

  const createTravelCompletion = (payload: UpsertTravelCompletionPayload) => {
    return api.post(
      `/financial-svc/v1/travel-completions?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const createTravelCompletionWithTravelRequest = (params: {
    id: string;
    cloneOnRequest: boolean;
  }) => {
    const queryString = stringify({ cloneOnRequest: params.cloneOnRequest });

    return api.post(
      `/financial-svc/v1/travel-completions?${queryString}`,
      { id: params.id },
      newCancelToken()
    );
  };

  const updateTravelCompletion = (payload: UpsertTravelCompletionPayload) => {
    return api.put(
      `/financial-svc/v1/travel-completions/${payload.id}?action=${payload.action}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteTravelCompletion = (params: { id: string; updateVersionNumber: number }) => {
    return api.delete(
      `/financial-svc/v1/travel-completions/${params.id}?updateVersionNumber=${params.updateVersionNumber}`,
      {},
      newCancelToken()
    );
  };

  const getTravelCompletionFileAttachmentPresignedUrl = (params: GetPresignedPOPayload) => {
    return api.get(
      `/financial-svc/v1/travel-completions/${params.id}/attachments/presigned-url`,
      params,
      newCancelToken()
    );
  };

  const getTravelCompletionFileAttachmentPresignedDownloadUrl = (
    params: GetPresignedPoAttachmentDownloadUrl
  ) => {
    return api.get(
      `/financial-svc/v1/travel-completions/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const addTravelCompletionAttachment = (payload: AddPoAttachmentPayload) => {
    return api.put(
      `/financial-svc/v1/travel-completions/${payload.documentId}/attachments`,
      {
        ...payload,
        documentId: undefined,
      },
      newCancelToken()
    );
  };

  const deleteTravelCompletionAttachment = (params: DeletePoAttachmentPayload) => {
    return api.delete(
      `/financial-svc/v1/travel-completions/${params.id}/attachments/${params.attachmentId}`,
      {},
      newCancelToken()
    );
  };

  const postTravelCompletionCloneDocument = (params: any) => {
    return api.post(
      `/financial-svc/v1/travel-completions/${params.id}/clone`,
      {},
      newCancelToken()
    );
  };

  const getFinalPdfTravelCompletion = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/travel-completions/${params.id}/final-pdf`);
  };

  // ====================== Global Settings ======================
  const getAllGlobalSettings = () => {
    return api.get('/financial-svc/v1/global-settings', {}, newCancelToken());
  };

  const updateGlobalSetting = (body: UpdateGlobalSettingPayload) => {
    const url = `/financial-svc/v1/global-setting/${body.settingId}`;
    return api.put(
      url,
      {
        settingValue: body.value,
      },
      newCancelToken()
    );
  };

  // ================== Purchasing List ===============
  const getAppPurchasingList = (params: GetPropertiesParams) => {
    params && delete params.sort;

    const queryString = stringify(params);
    return api.get(`/financial-svc/v1/purchase-orders?${queryString}`, {}, newCancelToken());
  };

  const getFinalPdfPurchaseOrder = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/purchase-orders/${params.id}/final-pdf`);
  };

  const patchPrintedPurchaseOrder = (params: GetPropertiesParams) => {
    return api.patch(`/financial-svc/v1/purchase-orders/${params.id}/printed`);
  };

  // ================== Non PO List ===============
  const getNonPOListing = (params: GetPropertiesParams) => {
    params && delete params.sort;

    const queryString = stringify(params);
    return api.get(`/financial-svc/v1/direct-payments?${queryString}`, {}, newCancelToken());
  };

  // ============== Travel Completion List =========
  const getAllTravelCompletionList = (params: GetPropertiesParams) => {
    params && delete params.sort;

    const queryString = stringify(params);
    return api.get(`/financial-svc/v1/travels?${queryString}`, {}, newCancelToken());
  };

  // ============== Check Register Listing =========
  const getCheckRegisterListing = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/financial-svc/v1/check-registers?${queryString}`, {}, newCancelToken());
  };

  const getCheckRegisterDetail = (params: { id: string; view: CHECK_REGISTER_DETAIL_VIEW }) => {
    const queryString = stringify({ view: params.view });

    return api.get(
      `/financial-svc/v1/check-registers/${params.id}?${queryString}`,
      {},
      newCancelToken()
    );
  };

  // ================== Check Printing ===============
  const getCurrentCheckPrinting = () => {
    return api.get(`/financial-svc/v1/check-printing/current`, {}, newCancelToken());
  };

  const getCheckPrintingById = (params: { id: string }) => {
    return api.get(`/financial-svc/v1/check-printing/${params.id}`, {}, newCancelToken());
  };

  const getUrlExportMicrCsv = () => {
    return api.post(`/financial-svc/v1/check-printing/current/csv`, {}, newCancelToken());
  };

  const getCheckPrintingPresignedDownloadUrl = (params: GetPresignedPoAttachmentDownloadUrl) => {
    return api.get(
      `/financial-svc/v1/check-printing/${params.id}/attachments/${params.attachmentId}/read`,
      params,
      newCancelToken()
    );
  };

  const patchCurrentCheckPrinting = (params: { action: string }) => {
    return api.patch(
      `/financial-svc/v1/check-printing/current/${params.action}`,
      {},
      newCancelToken()
    );
  };

  // ================== Reports ===============
  // ================== UH Reports ===============
  const getReportUserList = () => {
    return api.get(`/financial-svc/v1/rcuh-reports/users`, {}, newCancelToken());
  };

  const getKFSBudgetStatusReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/project-report/budget-status?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportBudgetStatusReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/kfs-reports/project-report/budget-status/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };
  const getUrlCurrentProjectAssignmentReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/kfs-reports/assignments-reports/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getDailyBalanceReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/project-report/daily-balance?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getSuspenseTransactionsReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/project-report/suspense?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportSuspenseTransactionsReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/kfs-reports/project-report/suspense/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getKFSMonthlyExpendituresReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/project-report/monthly-expenditures?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportKFSMonthlyExpendituresReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/kfs-reports/project-report/monthly-expenditures/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getKFSContinuationAccountTransactionsReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/project-report/continuation?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getCurrentProjectAssignmentsReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/assignments-reports?${queryString}`,
      {},
      newCancelToken()
    );
  };
  const getMassPrintingReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/mass-printing-report?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getKFSBudgetStatusSummaryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/kfs-reports/summary-budget-status?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportKFSBudgetStatusSummaryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/kfs-reports/summary-budget-status/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  // ================== RCUH Reports ===============
  const getRCUHMassPrintingReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/mass-printing-report?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getRCUHBudgetStatusSummaryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/budget-status-summary?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportRCUHBudgetStatusSummaryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/budget-status-summary/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getRCUHSubBudgetStatusSummaryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/sub-budget-status?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportRCUHSubBudgetStatusSummaryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/sub-budget-status/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getRCUHCurrentProjectAssignmentReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/assignments-reports?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlRCUHCurrentProjectAssignmentReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/assignments-reports/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  // ============ Search All Document =========
  const getSearchAllDocuments = (params: GetPropertiesParams) => {
    const queryString = stringify({ ...params, timezone: getCurrentTimeZone() });
    return api.get(`financial-svc/v1/documents?${queryString}`, {}, newCancelToken());
  };

  const getUrlSearchAllDocuments = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/financial-svc/v1/documents/csv?${queryString}`, {}, newCancelToken());
  };

  const getRCUHCurrentUserAssignmentReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/user-report?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getPayRollReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/payroll?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportPayRollReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/payroll/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getDetailOfCostReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/detail-of-cost?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportDetailOfCostReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/detail-of-cost/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const useGetProjectHistoryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/historic-report?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const useGetProjectHistoryPayrollReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/historic-report/payroll?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportProjectHistoryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/historic-report/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportProjectHistoryReportPayroll = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/historic-report/payroll/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getBudgetCategoryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/budget-category-review?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const useGetUrlExportBudgetCategoryReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/budget-category-review/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getVacationLeaveTransAndAdjustments = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/project-vac-trans?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportVacationLeaveTransAndAdjustments = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/project-vac-trans/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getVacationAndSickLeave = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/sick-leave?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportVacationAndSickLeave = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/sick-leave/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getSummaryVacationPlanning = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/project-report/vacation-summary?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportSummaryVacationPlanning = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/project-report/vacation-summary/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getSummaryBudgetStatusReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/budget-status-report?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportSummaryBudgetStatusReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/budget-status-report/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getConsolidatedExpenditure = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/rcuh-reports/expenditures-report?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportConsolidatedExpenditure = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/rcuh-reports/expenditures-report/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getVacationSickLeaveAudit = (params: GetPropertiesParams) => {
    return api.get(`/financial-svc/v1/rcuh-reports/vacation-audit`, {}, newCancelToken());
  };

  const postVacationSickLeaveAudit = (params: GetPropertiesParams) => {
    return api.post(
      `/financial-svc/v1/rcuh-reports/vacation-audit`,
      {
        ...params,
      },
      newCancelToken()
    );
  };

  // =========== Financial Report =================

  const getFinancialKFSBudgetStatusReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/pff-reports/kfs-budget-status?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportFinancialKFSBudgetStatusReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/pff-reports/kfs-budget-status/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getFinancialRcuhReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/pff-reports/rcuh-budget-status?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportFinancialRcuhReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.post(
      `/financial-svc/v1/pff-reports/rcuh-budget-status/csv?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getFinancialNonPayrollReport = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/financial-svc/v1/pff-reports/non-payroll?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportFinancialNonPayrollReport = (params: any) => {
    const queryString = stringify({
      project: params.project,
    });
    return api.post(
      `/financial-svc/v1/pff-reports/non-payroll/csv?${queryString}`,
      {
        ...params,
        project: undefined,
      },
      newCancelToken()
    );
  };

  const putCheckoutNonPayrollReport = (params: GetPropertiesParams) => {
    return api.put(
      `/financial-svc/v1/pff-reports/non-payroll/checkout`,
      {
        ...params,
      },
      newCancelToken()
    );
  };

  const putCheckinNonPayrollReport = (params: GetPropertiesParams) => {
    return api.put(
      `/financial-svc/v1/pff-reports/non-payroll/checkin`,
      {
        ...params,
      },
      newCancelToken()
    );
  };

  const putNonPayrollReport = (params: GetPropertiesParams) => {
    return api.put(
      `/financial-svc/v1/pff-reports/non-payroll`,
      {
        ...params,
      },
      newCancelToken()
    );
  };

  //Payroll projection report
  const getFinancialPayrollReport = (params: GetPropertiesParams) => {
    return api.get(
      `/financial-svc/v1/pff-reports/project-report/payroll/${params.project}`,
      {},
      newCancelToken()
    );
  };

  const getUrlExportFinancialPayrollReport = (params: any) => {
    return api.post(
      `/financial-svc/v1/pff-reports/project-report/payroll/${params.projectNumber}/csv`,
      {
        ...params,
        projectNumber: undefined,
      },
      newCancelToken()
    );
  };

  const putCheckoutPayrollReport = (params: GetPropertiesParams) => {
    return api.put(
      `/financial-svc/v1/pff-reports/project-report/payroll/${params.project}/checkout`,
      {
        ...params,
      },
      newCancelToken()
    );
  };

  const putCheckinPayrollReport = (params: GetPropertiesParams) => {
    return api.put(
      `/financial-svc/v1/pff-reports/project-report/payroll/${params.project}/checkin`,
      {
        ...params,
      },
      newCancelToken()
    );
  };

  const putPayrollReport = (params: GetPropertiesParams) => {
    return api.put(
      `/financial-svc/v1/pff-reports/project-report/payroll/${params.projectNumber}/update`,
      {
        ...params,
        projectNumber: undefined,
      },
      newCancelToken()
    );
  };

  // Notification
  const getNotification = (params: { isCurrent: boolean }) => {
    return api.get(
      `/financial-svc/v1/notifications?isCurrent=${params.isCurrent}`,
      {},
      newCancelToken()
    );
  };
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== Auth ======================
    confirmSignIn,
    signIn,

    signOut,
    forgotPassword,
    submitForgotPassword,
    changePassword,
    completeNewPassword,
    confirmPassword,

    // ================== Miscellaneous ===============
    getNetworkStatus,

    // ================== Miscellaneous: Staff Listing ===============
    getStaffListing,
    updateStaffListing,

    // ====================== File ======================
    getPresignedUserServiceUrl,
    uploadFile,
    uploadFileWithProgress,
    getDecodeUserServiceUrl,

    // ====================== Content ======================
    getContents,
    getCityStateByZipCode,
    getPICodes,
    getFACodes,

    // ====================== Profile ======================
    getMyProfile,
    // updateMyProfile,
    updateMyProfile,
    updateProfilePasswordResetRequired,
    updateCurrentRoleMyProfile,
    getDelegationAccesses,
    getTokenDelegation,

    // ====================== Permissions ======================
    getMyPermissions,
    getPermissionCu,

    // ====================== Users Management ======================
    getAllUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    searchUsers,
    getUrlExportUsers,
    updateUserLastPasswordChanged,

    // ====================== Projects ======================
    getFinancialProjects,
    getProfileProjects,

    // ====================== Vendors ======================
    searchVendors,
    getAllVendors,
    createVendorsRecord,
    updateVendorRecord,
    getVendorRecordById,
    createVendorRegistration,
    getVendorRegistrationById,
    updateVendorRegistration,
    getVendorRegistrationFileAttachmentPresignedUrl,
    getVendorRegistrationFileAttachmentPresignedDownloadUrl,
    addVendorRegistrationAttachment,
    deleteVendorRegistrationAttachment,

    // ====================== Super Quotes ======================
    searchSuperQuotes,
    importSuperQuotes,

    // ====================== PO ======================
    getPODetail,
    createPO,
    updatePO,
    deletePO,
    getPoFileAttachmentPresignedUrl,
    getPoFileAttachmentPresignedDownloadUrl,
    addPoAttachment,
    deletePOAttachment,
    postPOCloneDocument,

    // ====================== PO Change ======================
    createPOChange,

    // ====================== PO Payment ======================
    getPOPaymentDetail,
    createPOPayment,
    updatePOPayment,
    deletePOPayment,
    getPOPaymentRemainingBalance,

    // ====================== Non PO Payment ======================
    // ====================== Authorization Payment ======================
    postAuthorizationPaymentCloneDocument,
    createAuthorizationPayment,
    deleteAuthorizationPaymentsAttachment,
    deleteAuthorizationPayment,
    getAuthorizationPaymentDetail,
    updateAuthorizationPayment,
    getAuthorizationPaymentFileAttachmentPresignedUrl,
    getAuthorizationPaymentFileAttachmentPresignedDownloadUrl,
    getAuthorizationPaymentFinalPdf,
    addAuthorizationPaymentAttachment,

    // ====================== Non Employee Travel ======================
    getNonEmployeeTravelDetail,
    createNonEmployeeTravel,
    updateNonEmployeeTravel,
    deleteNonEmployeeTravel,
    getNonEmployeeTravelFileAttachmentPresignedUrl,
    getNonEmployeeTravelFileAttachmentPresignedDownloadUrl,
    addNonEmployeeTravelAttachment,
    deleteNonEmployeeTravelAttachment,
    postNonEmployeeTravelCloneDocument,
    getFinalPdfNonEmployeeTravel,

    // ====================== Petty Cash Summary ======================
    getPettyCashDetail,
    createPettyCash,
    updatePettyCash,
    deletePettyCash,
    getPettyCashFileAttachmentPresignedUrl,
    getPettyCashFileAttachmentPresignedDownloadUrl,
    addPettyCashAttachment,
    deletePettyCashAttachment,
    postPettyCashCloneDocument,
    getFinalPdfPettyCashPayment,

    // ====================== Personal Automobile  ======================
    createPersonalAutoCloneDocument,
    createPersonalAutomobile,
    getPersonalAutomobileFileAttachmentPresignedUrl,
    getPersonalAutomobileFileAttachmentPresignedDownloadUrl,
    addPersonalAutoAttachment,
    deletePersonalAutoAttachment,
    getPersonalAutoDetail,
    updatePersonalAutomobile,
    getPersonalAutoFinalPDF,
    deletePersonalAuto,

    // ====================== Reimbursement For Cash  ======================
    getReimbursementForCashDetail,
    getReimbursementForCashFileAttachmentPresignedUrl,
    getFinalPdfReimbursement,

    // ====================== Multi Intra State Travel   ======================
    getMultiIntraStateTravelDetail,
    getMultiIntraStateTravelFileAttachmentPresignedUrl,
    getFinalPdfMultiIntraStateTravel,

    // ====================== Travel Request ======================
    postTravelRequestCloneDocument,
    createTravelRequest,
    deleteTravelRequestAttachment,
    deleteTravelRequest,
    getTravelRequestDetail,
    updateTravelRequest,
    getTravelRequestFileAttachmentPresignedUrl,
    getTravelRequestFileAttachmentPresignedDownloadUrl,
    getFinalPdfTravelRequest,
    addTravelRequestAttachment,

    // ====================== Travel Completion ======================
    postTravelCompletionCloneDocument,
    createTravelCompletion,
    deleteTravelCompletionAttachment,
    deleteTravelCompletion,
    getTravelCompletionDetail,
    updateTravelCompletion,
    getTravelCompletionFileAttachmentPresignedUrl,
    getTravelCompletionFileAttachmentPresignedDownloadUrl,
    getFinalPdfTravelCompletion,
    addTravelCompletionAttachment,
    createTravelCompletionWithTravelRequest,

    // ================== Purchasing List ===============
    getAppPurchasingList,
    getFinalPdfPurchaseOrder,
    patchPrintedPurchaseOrder,

    // ================== Non PO List ===============
    getNonPOListing,

    // ================= Travel Completion ==========
    getAllTravelCompletionList,

    // ================= Check Register ==========
    getCheckRegisterListing,
    getCheckRegisterDetail,

    // ================= Check Printing ==========
    getCurrentCheckPrinting,
    getUrlExportMicrCsv,
    patchCurrentCheckPrinting,
    getCheckPrintingPresignedDownloadUrl,
    getCheckPrintingById,

    // ================== Global Setting ===============
    getAllGlobalSettings,
    updateGlobalSetting,

    // ================== Reports ===============
    getReportUserList,

    getKFSBudgetStatusReport,
    getDailyBalanceReport,
    getSuspenseTransactionsReport,
    getUrlExportSuspenseTransactionsReport,
    getKFSMonthlyExpendituresReport,
    getUrlExportKFSMonthlyExpendituresReport,
    getKFSContinuationAccountTransactionsReport,
    getUrlExportBudgetStatusReport,
    getUrlCurrentProjectAssignmentReport,

    getKFSBudgetStatusSummaryReport,
    getUrlExportKFSBudgetStatusSummaryReport,

    getCurrentProjectAssignmentsReport,
    getMassPrintingReport,

    // RCUH Report
    getRCUHMassPrintingReport,
    getRCUHCurrentProjectAssignmentReport,
    getUrlRCUHCurrentProjectAssignmentReport,
    getRCUHBudgetStatusSummaryReport,
    getRCUHSubBudgetStatusSummaryReport,
    getUrlExportRCUHBudgetStatusSummaryReport,
    getUrlExportRCUHSubBudgetStatusSummaryReport,

    // =========== Search All Documents =======
    getSearchAllDocuments,
    getUrlSearchAllDocuments,
    getRCUHCurrentUserAssignmentReport,
    getPayRollReport,
    getUrlExportPayRollReport,
    getDetailOfCostReport,
    getUrlExportDetailOfCostReport,
    useGetProjectHistoryReport,
    useGetProjectHistoryPayrollReport,
    getUrlExportProjectHistoryReport,
    getUrlExportProjectHistoryReportPayroll,
    getBudgetCategoryReport,
    useGetUrlExportBudgetCategoryReport,
    getVacationLeaveTransAndAdjustments,
    getUrlExportVacationLeaveTransAndAdjustments,
    getVacationAndSickLeave,
    getUrlExportVacationAndSickLeave,
    getSummaryVacationPlanning,
    getUrlExportSummaryVacationPlanning,
    getSummaryBudgetStatusReport,
    getUrlExportSummaryBudgetStatusReport,
    getConsolidatedExpenditure,
    getUrlExportConsolidatedExpenditure,
    getVacationSickLeaveAudit,
    postVacationSickLeaveAudit,

    // =========== Financial Report =================
    getFinancialKFSBudgetStatusReport,
    getUrlExportFinancialKFSBudgetStatusReport,
    getFinancialRcuhReport,
    getUrlExportFinancialRcuhReport,
    getFinancialNonPayrollReport,
    getUrlExportFinancialNonPayrollReport,
    putCheckoutNonPayrollReport,
    putCheckinNonPayrollReport,
    putNonPayrollReport,
    getFinancialPayrollReport,
    getUrlExportFinancialPayrollReport,
    putCheckoutPayrollReport,
    putCheckinPayrollReport,
    putPayrollReport,

    // ============ Notification ================
    getNotification,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
