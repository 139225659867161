import { QueryFunction, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { AuditGlobalSetting, GlobalSettings } from './types';

export function useGlobalSettings(
  options?: UseQueryOptions<
    ApiResponseType<{ settings: GlobalSettings; auditTrails: AuditGlobalSetting[] }>,
    Error,
    any
  >
) {
  const handleGetAllGlobalSettings: QueryFunction<
    ApiResponseType<{ settings: GlobalSettings; auditTrails: AuditGlobalSetting[] }>,
    API_QUERIES
  > = () =>
    responseWrapper<
      ApiResponseType<{ settings: GlobalSettings; auditTrails: AuditGlobalSetting[] }>
    >(apiClient.getAllGlobalSettings);

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getAllGlobalSettings,
  } = useQuery<
    ApiResponseType<{ settings: GlobalSettings; auditTrails: AuditGlobalSetting[] }>,
    Error,
    any
  >([API_QUERIES.GLOBAL_SETTINGS], {
    queryFn: handleGetAllGlobalSettings,
    select(data) {
      return data.data;
    },
    enabled: true,
    notifyOnChangeProps: ['data', 'isError', 'isFetching'],
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateGlobalSettings = () =>
    queryClient.invalidateQueries([API_QUERIES.GLOBAL_SETTINGS]);

  return {
    globalSettings: data?.settings,
    auditTrails: data?.auditTrails,
    error,
    isError,
    loading: isFetching,
    getAllGlobalSettings,
    handleInvalidateGlobalSettings,
  };
}
