import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';

export function useTravelCompletionCloneDocument(options?: UseMutationOptions<any, Error, any>) {
  const { mutate: travelCompletionCloneDocument, isLoading } = useMutation<any, Error, any>({
    mutationFn: (payload: { id: string }) =>
      responseWrapper(apiClient.postTravelCompletionCloneDocument, [payload]),
    ...options,
  });

  return {
    travelCompletionCloneDocument,
    isLoading,
  };
}
