import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { UHReportParams } from 'src/containers/UHReportContainer/types';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { KFSBudgetStatusReportResponse } from './types';

export function useGetKFSBudgetStatusReport(
  options?: UseQueryOptions<
    ApiResponseType<KFSBudgetStatusReportResponse>,
    Error,
    KFSBudgetStatusReportResponse
  > &
    Partial<UHReportParams>
) {
  const Role = RoleService.getCurrentRole();

  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<
    ApiResponseType<KFSBudgetStatusReportResponse>,
    Error,
    KFSBudgetStatusReportResponse
  >([API_QUERIES.KFS_BUDGET_STATUS_REPORT, { project: options.project, role: Role }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<KFSBudgetStatusReportResponse>>(
        apiClient.getKFSBudgetStatusReport,
        params
      );
    },
    select: getResponseData,
    ...options,
  });

  const queryClient = useQueryClient();
  const handleKFSBudgetStatusReport = () =>
    queryClient.invalidateQueries(API_QUERIES.KFS_BUDGET_STATUS_REPORT);

  return {
    data,
    error,
    isError,
    isLoading,
    refetch,
    handleKFSBudgetStatusReport,
  };
}
