import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { IoInformationCircle } from 'react-icons/io5';
import { COLOR_CODE } from 'src/appConfig/constants';

const InfoTooltip = ({ title = '' }: { title: string | React.ReactNode }) => {
  return (
    <Tooltip title={title} arrow className="hidden-print">
      <Box>
        <IoInformationCircle
          size={18}
          color={COLOR_CODE.INFO}
          style={{
            transform: 'translateY(2px)',
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default React.memo(InfoTooltip);
