import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';

export function useDeletePersonalAuto(
  options?: UseMutationOptions<any, Error, { id: string; updateVersionNumber: number }>
) {
  const { mutate: deletePersonalAuto, isLoading } = useMutation<
    any,
    Error,
    { id: string; updateVersionNumber: number }
  >({
    mutationFn: (payload: { id: string; updateVersionNumber: number }) =>
      responseWrapper(apiClient.deletePersonalAuto, [payload]),
    ...options,
  });

  return {
    deletePersonalAuto,
    isLoading,
  };
}
