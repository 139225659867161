import { Box } from '@mui/material';

export const PrintTextArea = (props: { value: string | number | readonly string[] }) => {
  return (
    <Box
      sx={{
        display: 'none',
        whiteSpace: 'pre-line',
      }}
      className="print__display--textArea"
    >
      {props.value}
    </Box>
  );
};
