import { useState } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, authResponseWrapper } from 'src/queries/helpers';
import { CurrentProjectAssignmentParams, UrlExport } from './types';

export function useGetUrlCurrentProjectAssignmentReport(
  options?: UseMutationOptions<
    ApiResponseType<UrlExport>,
    Error,
    CurrentProjectAssignmentParams
  > & {}
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlCurrentProjectAssignmentReport, isLoading } = useMutation<
    ApiResponseType<UrlExport>,
    Error,
    CurrentProjectAssignmentParams
  >({
    mutationFn: (params: CurrentProjectAssignmentParams) => {
      return authResponseWrapper<ApiResponseType<UrlExport>>(
        apiClient.getUrlCurrentProjectAssignmentReport,
        [params]
      );
    },
    ...options,
  });

  return {
    getUrlCurrentProjectAssignmentReport,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
