import { UseMutationOptions, useMutation } from 'react-query';
import { DeletePoAttachmentPayload } from 'src/queries/PurchaseOrders';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';

export function useDeleteTravelCompletionAttachment(
  options?: UseMutationOptions<any, Error, DeletePoAttachmentPayload>
) {
  const { mutate: deleteTravelCompletionAttachment, isLoading } = useMutation<
    any,
    Error,
    DeletePoAttachmentPayload
  >({
    mutationFn: (payload: DeletePoAttachmentPayload) =>
      responseWrapper(apiClient.deleteTravelCompletionAttachment, [payload]),
    ...options,
  });

  return {
    deleteTravelCompletionAttachment,
    isLoading,
  };
}
