export enum PO_DETAIL_STATUS {
  PI_PENDING_SUBMITTAL = 'PI_PENDING_SUBMITTAL',
  FA_PENDING_APPROVAL = 'FA_PENDING_APPROVAL',
  PI_ADDITIONAL_INFO_REQUESTED = 'PI_ADDITIONAL_INFO_REQUESTED',
  PI_DISAPPROVED = 'PI_DISAPPROVED',
  FA_ADDITIONAL_INFO_REQUESTED_RCUH = 'FA_ADDITIONAL_INFO_REQUESTED_RCUH',
  RCUH_PENDING_RCUH_APPROVAL = 'RCUH_PENDING_RCUH_APPROVAL',
  FINAL = 'FINAL',
}

export enum PO_DOCUMENT_TYPE {
  PURCHASE_ORDER = 'purchaseOrder',
  PO_CHANGE = 'poChange',
  PO_PAYMENT = 'poPayment',
}

export enum PO_ACTION {
  //for payload and state purpose
  SAVE = 'save',
  SUBMIT = 'submit',
  APPROVE = 'approve',
  DISAPPROVE = 'disapprove',
  ADDITIONAL_INFO = 'additionalInfo',

  //for state purpose
  DELETE = 'delete',
}

export enum ADDITIONAL_INFO_ACTION {
  SAVE = 'save',
}

export enum PO_MODE {
  CREATE = 'CREATE',
  PI_SU_EDIT_PENDING_SUBMITTAL = 'PI_SU_EDIT_PENDING_SUBMITTAL',
  VIEW_ONLY = 'VIEW_ONLY',
  FINAL = 'FINAL',
  FA_REVIEW = 'FA_REVIEW',
  CU_REVIEW = 'CU_REVIEW',
}

export enum CLASSIFICATION {
  RCUH = 'RCUH',
  UH = 'UH',
}
