//TODO: check value
export enum TRAVEL_DOCUMENT_TYPE {
  REQUEST = 'travelRequest',
  COMPLETION_WITHOUT_REQUEST = 'travelCompletionWithoutRequest',
  COMPLETION_WITH_REQUEST = 'travelCompletionWithRequest',
}

export enum EXPENDITURE_PAYMENT_METHOD {
  PCARD = 'pcard',
  CASH_CHECK = 'cash',
  PURCHASE_ORDER = 'po',
  PERSONAL_CCARD = 'pcc',
}
