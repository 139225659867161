import { useState } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, authResponseWrapper } from 'src/queries/helpers';
import { CurrentProjectAssignmentParams } from '../UHProjectFiscal/types';
import { UrlExport } from './types';

type Export = {
  data: UrlExport;
};

export function useGetUrlRCUHCurrentProjectAssignmentReport(
  options?: UseMutationOptions<ApiResponseType<Export>, Error, CurrentProjectAssignmentParams> & {}
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlRCUHCurrentProjectAssignmentReport, isLoading } = useMutation<
    ApiResponseType<Export>,
    Error,
    CurrentProjectAssignmentParams
  >({
    mutationFn: (params: CurrentProjectAssignmentParams) => {
      return authResponseWrapper<ApiResponseType<Export>>(
        apiClient.getUrlRCUHCurrentProjectAssignmentReport,
        [params]
      );
    },
    ...options,
  });

  return {
    getUrlRCUHCurrentProjectAssignmentReport,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
