import { useState } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, authResponseWrapper } from 'src/queries/helpers';
import { UrlExport } from '../Users';

export function useGetUrlExportMicrCsv(
  options?: UseMutationOptions<ApiResponseType<{ data: UrlExport }>, Error, any> & {}
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlExportMicrCsv, isLoading } = useMutation<
    ApiResponseType<{ data: UrlExport }>,
    Error,
    any
  >({
    mutationFn: () => {
      return authResponseWrapper<ApiResponseType<{ data: UrlExport }>>(
        apiClient.getUrlExportMicrCsv
      );
    },
    ...options,
  });

  return {
    getUrlExportMicrCsv,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
