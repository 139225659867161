import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPrintState {
  isExpandedFa: boolean;
  isExpandedSu: boolean;
  isExpandedPi: boolean;
}

const initialState: IPrintState = {
  isExpandedFa: false,
  isExpandedSu: false,
  isExpandedPi: false,
};

export const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    toggleExpandFa: (state, action: PayloadAction<boolean>) => {
      state.isExpandedFa = action.payload;
    },
    toggleExpandSu: (state, action: PayloadAction<boolean>) => {
      state.isExpandedSu = action.payload;
    },
    toggleExpandPi: (state, action: PayloadAction<boolean>) => {
      state.isExpandedPi = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleExpandFa, toggleExpandSu, toggleExpandPi } = printSlice.actions;

export const printState = printSlice.getInitialState();

export default printSlice.reducer;
