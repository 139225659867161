import { ErrorOutline } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { COLOR_CODE } from 'src/appConfig/constants';

const ReportErrorSection: React.FC<Props> = ({ errorMessage }) => {
  return (
    <>
      {errorMessage ? (
        <Box
          p={2}
          bgcolor={'#fff6f6'}
          whiteSpace={'pre-wrap'}
          border={COLOR_CODE.DEFAULT_BORDER}
          borderColor={'error.main'}
          color={'error.main'}
          mb={2}
        >
          <Stack direction={'row'} alignItems={'center'} mb={1}>
            <ErrorOutline fontSize="small" />
            <Typography ml={1} color={'error.main'} fontWeight={'bold'}>
              System Errors:{' '}
            </Typography>
          </Stack>
          <Typography fontSize={13} color="error">
            {errorMessage}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

interface Props {
  errorMessage: string;
}

export default ReportErrorSection;
