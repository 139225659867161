import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Select, { GetStyles, GroupBase, Options, components } from 'react-select';
import { Checkbox, Element, View } from 'src/components/common';
import { getRandomId } from 'src/utils';
import { CommonPlacement } from 'src/utils/commonTypes';
import './styles.scss';

export const MultiSelectCheckbox = ({
  errorMessage,
  label,
  containerClassName = '',
  required = false,
  extraRequired = false,
  infoTooltipMessage,
  infoTooltipPlacement = 'right',
  infoToolTipWithArrow,
  footer,
  placeholder = 'Select',
  name = '',
  onChange,
  options,
  value,
  numberOfSelectedOptions = 3,
}: MultiSelectCheckboxProps) => {
  const id = useRef(`select-${getRandomId()}`);
  const ref = useRef(null);

  const handleSelectAll = () => {
    onChange(name, options);
  };

  const handleClearAll = () => {
    onChange(name, []);
  };

  const handleChange = (selectedOption) => {
    onChange(name, selectedOption);
  };

  const Option = (props) => {
    if (props.value === 'All')
      return (
        <div className="multiselect_checkbox-control_all">
          <View className="btn_control" onClick={handleSelectAll}>
            Select All
          </View>
          <View className="btn_control" onClick={handleClearAll}>
            Clear All
          </View>
        </div>
      );
    return (
      <div>
        <components.Option
          {...props}
          onClick={() => {
            ref.current.focus();
          }}
        >
          <Checkbox.Item
            label={props.label}
            checked={props.isSelected}
            onChange={() => null}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => {
    let labelToBeDisplayed = `${props.data.value}`;
    return (
      <components.MultiValue {...props}>
        <span>{labelToBeDisplayed}</span>
      </components.MultiValue>
    );
  };

  const ValueContainer = <IsMulti extends boolean = false>({
    children,
    hasValue,
    isMulti,
    clearValue,
    cx,
    getStyles,
    getValue,
    isDisabled,
    options,
    selectProps,
  }: ValueContainerProps<IsMulti>) => {
    let toBeRendered = children;
    let remaining = '';
    if (children[0]?.length > numberOfSelectedOptions) {
      const getChildren = children[0].slice(0, numberOfSelectedOptions);
      remaining = `+ ${children[0].length - numberOfSelectedOptions}`;
      toBeRendered = [[...getChildren], children[1]];
    }

    return (
      <components.ValueContainer
        getClassNames={() => null}
        isRtl={false}
        selectOption={() => {}}
        setValue={undefined}
        theme={undefined}
        clearValue={clearValue}
        cx={cx}
        getStyles={() => null}
        getValue={getValue}
        hasValue={hasValue}
        isDisabled={isDisabled}
        isMulti={isMulti}
        options={options}
        selectProps={selectProps}
      >
        {toBeRendered}
        {remaining ?? <span>{remaining}</span>}
      </components.ValueContainer>
    );
  };

  const [startFocus, setStartFocus] = useState(false);

  useEffect(() => {
    if (startFocus) {
      ref.current?.focus();
    }
  });

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
      extraRequired={extraRequired}
      infoTooltipMessage={infoTooltipMessage}
      infoTooltipPlacement={infoTooltipPlacement}
      infoToolTipWithArrow={infoToolTipWithArrow}
    >
      {/* <View> */}
      <Select
        id={id.current}
        ref={ref}
        className="multiselect_checkbox"
        placeholder={placeholder}
        styles={{
          multiValue: (base) => ({
            ...base,
            borderRadius: `8px`,
            backgroundColor: '#F8F8F8',
          }),
          option: (base, state) => ({
            ...base,
            background: 'none',
            hover: {
              backgroundColor: '#ECECEC',
            },
          }),
          menuPortal: (base, props) => ({
            ...base,
            zIndex: 999,
          }),
        }}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, ValueContainer }}
        options={[{ value: 'All', label: 'All' }, ...options]}
        value={value}
        onChange={(value) => handleChange(value)}
        isClearable={false}
        onFocus={() => {
          setStartFocus(true);
        }}
        onBlur={() => {
          setStartFocus(false);
        }}
      />
      {/* </View> */}
      {footer && <Box mt={1}>{footer}</Box>}
    </Element>
  );
};

interface ValueContainerProps<
  IsMulti extends boolean = false
  // GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
> {
  children: React.ReactNode;
  hasValue: boolean;
  isMulti: IsMulti;
  clearValue: () => void;
  cx: (...classNames: Array<string | null | undefined | Record<string, boolean>>) => string;
  getStyles: GetStyles<IsMulti, boolean, GroupBase<IsMulti>>;
  getValue: () => Options<IsMulti>;
  isDisabled: boolean;
  options: Options<IsMulti>;
  selectProps: any;
}

type MultiSelectCheckboxProps = {
  placeholder: string;
  errorMessage?: string;
  label?: string;
  containerClassName?: string;
  required?: boolean;
  extraRequired?: boolean;
  infoTooltipMessage?: string;
  infoTooltipPlacement?: CommonPlacement;
  infoToolTipWithArrow?: boolean;
  footer?: React.ReactNode;
  onChange: (name: string, value: any) => void;
  value: any;
  options: any;
  name: string;
  numberOfSelectedOptions?: number;
};
