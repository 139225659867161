import { useEffect, useState } from 'react';

const useHandleErrorMessageReport = ({ loading }) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (loading) {
      setErrorMessage('');
    }
  }, [loading]);

  return { errorMessage, setErrorMessage };
};

export default useHandleErrorMessageReport;
