import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import { NavigateQuery } from 'src/containers/shared';
import { Navigator } from 'src/services';

const useGetWorkFlowStatus = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const searchText = query.get(NavigateQuery.SEARCH_TEXT);
  const params = new URLSearchParams(searchText);
  const workflowStatus = params.get(NavigateQuery.WORK_FLOW_STATUS);
  const prevPage = query.get(NavigateQuery.PREVIOUS_PAGE);
  const navigateDashboard = () => {
    Navigator.navigate(`${PATHS.dashboard}`);
  };
  return { workflowStatus, searchText, navigateDashboard, prevPage };
};

export default useGetWorkFlowStatus;
