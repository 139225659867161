import dayjs from 'dayjs';
import _ from 'lodash';
import { isEmpty } from 'src/validations';

export const moneyReg = /-?[\d,]+\.{0,1}\d{0,}/;

export const MoneyInputDetect = (value) => `${value}`.match(moneyReg)?.[0] || '';

export const convertCurrencyInputToString = (value) => {
  return value.replace(/[^0-9.-]+/g, '');
};

export const formatDate = (value: string, format: string = 'MM/DD/YYYY') => {
  if (!value) return '';

  return dayjs(value).format(format);
};

export const formatDateWithTimeZone = (value: string, format: string = 'MM/DD/YYYY') => {
  var customDayjs = require('dayjs');
  var timezone = require('dayjs/plugin/timezone');
  customDayjs.extend(timezone);
  return customDayjs(value).tz('US/Hawaii').format(format);
};

export const formatShowSSN = (value: string, from: number = 6) => {
  return `*******${value.slice(from)}`;
};

export const formatMoney = (
  value: number | any,
  options: {
    defaultValue?: string;
    currency?: string;
    style?: 'currency';
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
  } = {
    defaultValue: '',
    currency: 'USD',
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }
) => {
  const { defaultValue, style, currency, maximumFractionDigits, minimumFractionDigits } = options;

  if (isNaN(value)) return defaultValue;

  return (+value).toLocaleString('en-US', {
    style,
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  });
};

export const formatMoneyInput = (value: number) => {
  if (!value) return '';
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
};

export const formatStringToNumber = (value: string) => {
  if (isEmpty(value)) return null;
  return Number(value);
};

export const capitalizeWords = (string: string) => {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const getStartCase = (value: string): string => {
  return value ? _.startCase(value.toLowerCase()) : '';
};

export const getTitleCase = (str: string): string => {
  if (!str) return '';
  return _.startCase(_.toLower(str));
};

export const removeSpecialCharacterFromString = (value: string) => {
  return value.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const formatStringToJson = (str: string) => {
  try {
    const object = JSON.parse(str);

    return object;
  } catch (error) {
    console.error('formatStringToJson', error);

    return {};
  }
};
