export enum VENDOR_KEY {
  CODE = 'code',
  VENDOR_NAME = 'vendorName',
  VENDOR_NAME_2 = 'vendorName2',
  // TODO: Tuyen Tran will replace name,name2 if don't need
  NAME = 'name',
  NAME2 = 'name2',
  TYPE = 'type',
  PAYMENT_TYPE = 'paymentType',
  PURGED = 'purged',
  ADDRESS1 = 'address1',
  ADDRESS2 = 'address2',
  ADDRESS3 = 'address3',
  CREATED_DATE = 'createdDate',
  MODIFIED_DATE = 'modifiedDate',
  W9 = 'w9',
  W9_TOOLTIP = 'w9Tooltip',
  ZIP = 'zip',
  ZIP_4 = 'zip4',
  EMP_ID = 'employeeId',
  RCUH_EMP_NUMBER = 'rcuhEmpNumber',
  UH_EMP_NUMBER = 'uhEmpNumber',
  SSN = 'ssn',
  VENDOR_REGISTRATION_EXISTS = 'vendorRegistrationExists',
}
