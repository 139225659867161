import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { TravelCompletionDetailResponse } from './types';

export function useCreateTravelCompletionWithTravelRequest(
  options?: UseMutationOptions<
    ApiResponseType<TravelCompletionDetailResponse>,
    Error,
    { id: string; cloneOnRequest: boolean }
  >
) {
  const {
    mutate: createTravelCompletionWithTravelRequest,
    data,
    isLoading,
    isSuccess,
  } = useMutation<
    ApiResponseType<TravelCompletionDetailResponse>,
    Error,
    { id: string; cloneOnRequest: boolean }
  >({
    mutationFn: (payload: { id: string; cloneOnRequest: boolean }) =>
      responseWrapper(apiClient.createTravelCompletionWithTravelRequest, [payload]),
    ...options,
  });

  return {
    createTravelCompletionWithTravelRequest,
    data,
    isLoading,
    isSuccess,
  };
}
