import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import apiClient from '../apiClient';
import { API_QUERIES } from '../keys';
import { VendorRegistrationResponse } from './types';

export function useGetVendorRegistrationDetail(
  options?: UseQueryOptions<
    ApiResponseType<VendorRegistrationResponse>,
    Error,
    VendorRegistrationResponse
  > & { code: string }
) {
  const {
    data: vendorRegistrationDetail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetVendorRegistrationById,
  } = useQuery<ApiResponseType<VendorRegistrationResponse>, Error, VendorRegistrationResponse>(
    [API_QUERIES.VENDOR_REGISTRATION, { code: options.code }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<VendorRegistrationResponse>>(
          apiClient.getVendorRegistrationById,
          params
        );
      },
      select: getResponseData,
      enabled: false,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateVendorRegistrationDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.VENDOR_REGISTRATION);

  return {
    vendorRegistrationDetail,
    error,
    isError,
    isLoading,
    onGetVendorRegistrationById,
    handleInvalidateVendorRegistrationDetail,
  };
}
