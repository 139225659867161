import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { BudgetStatusSummaryReportParams, BudgetStatusSummaryReportResponse } from './types';

export function useGetBudgetStatusSummaryReport(
  options?: UseQueryOptions<
    ApiResponseType<BudgetStatusSummaryReportResponse>,
    Error,
    BudgetStatusSummaryReportResponse
  > &
    BudgetStatusSummaryReportParams
) {
  const role = RoleService.getCurrentRole();
  const {
    data: budgetStatusSummaryReport,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetBudgetStatusSummaryReport,
  } = useQuery<
    ApiResponseType<BudgetStatusSummaryReportResponse>,
    Error,
    BudgetStatusSummaryReportResponse
  >(
    [
      API_QUERIES.KFS_BUDGET_STATUS_SUMMARY_REPORT,
      {
        userId: options.userId,
        code: options.code,
        fullName: options.fullName,
        roleCode: options.roleCode,
        role: role,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<BudgetStatusSummaryReportResponse>>(
          apiClient.getKFSBudgetStatusSummaryReport,
          params
        );
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateBudgetStatusSummaryReport = () =>
    queryClient.invalidateQueries(API_QUERIES.KFS_BUDGET_STATUS_SUMMARY_REPORT);

  return {
    budgetStatusSummaryReport,
    error,
    isError,
    isLoading,
    onGetBudgetStatusSummaryReport,
    handleInvalidateBudgetStatusSummaryReport,
  };
}
