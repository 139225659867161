import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { TravelRequestDetailResponse } from './types';

//use case: for get detail data without caching
export function useGetTravelRequestDetailMutation(
  options?: UseMutationOptions<ApiResponseType<TravelRequestDetailResponse>, Error, { id: string }>
) {
  const {
    mutate: getTravelRequestDetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<TravelRequestDetailResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) =>
      responseWrapper(apiClient.getTravelRequestDetail, [params]),
    ...options,
  });

  return {
    getTravelRequestDetailMutation,
    isLoading,
    isSuccess,
  };
}
