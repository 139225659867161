import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { memo } from 'react';
import {
  Button,
  Checkbox,
  EllipsisTooltipInput,
  EllipsisTooltipInputCurrency,
  Input,
  InputAutosize,
  RadioButton,
  Select,
  TextareaAutosize,
} from 'src/components/common';
import { Yup } from 'src/services';
import { getUncontrolledInputFieldProps } from 'src/utils';

type DemoFormValues = {
  controlledInput: string;
  uncontrolledInput: string;
  ellipsisTooltipInput: string;
  number: number;
  currencyUSD: string;
  textareaAutosizeVertical: string;
  textareaAutosizeNoneResize: string;
  select: string;
  checkbox: string[];
  radio: string;
  date: Date;
  phone: string;
};

const initialDemoFormValues = {
  uncontrolledInput: '',
  controlledInput: '',
  ellipsisTooltipInput: '',
  number: null,
  currencyUSD: '',
  textareaAutosizeVertical: '',
  textareaAutosizeNoneResize: '',
  select: null,
  checkbox: [],
  radio: null,
  date: null,
  phone: '',
};

const demoFormSchema = Yup.object({
  controlledInput: Yup.string().required(),
  uncontrolledInput: Yup.string().required(),
});

const FormikDemo: React.FC<Props> = () => {
  const onSubmit = (data: DemoFormValues) => {
    console.log('formik data', data);
  };

  console.log('~~ Formik re-render');

  const { values, setFieldValue, setFieldTouched, handleSubmit, getFieldProps } =
    useFormik<DemoFormValues>({
      initialValues: initialDemoFormValues,
      validationSchema: demoFormSchema,
      onSubmit: onSubmit,
    });

  const getUncontrolledFieldProps = getUncontrolledInputFieldProps({
    values,
    setFieldTouched,
    setFieldValue,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label={
          <Typography variant="body2">
            <b>Input</b> - Uncontrolled
          </Typography>
        }
        {...getUncontrolledFieldProps(`uncontrolledInput`)}
      />
      <Input
        label={
          <Typography variant="body2">
            <b>Input</b> - Controlled
          </Typography>
        }
        {...getFieldProps('controlledInput')}
        containerClassName="mt-16"
      />

      <EllipsisTooltipInput
        label={
          <Typography variant="body2">
            <b>Input</b> - Ellipsis Tooltip (Type over 10 character)
          </Typography>
        }
        {...getFieldProps(`ellipsisTooltipInput`)}
        lengthShowTooltip={10}
        containerClassName="mt-16"
      />

      <EllipsisTooltipInput
        label={
          <Typography variant="body2" mr={1}>
            <b>Input Number</b>
          </Typography>
        }
        {...getFieldProps(`number`)}
        lengthShowTooltip={10}
        type={'number'}
        containerClassName="mt-16"
      />

      <EllipsisTooltipInputCurrency
        label={
          <Typography variant="body2" mr={1}>
            <b>USD</b>
          </Typography>
        }
        {...getFieldProps(`currencyUSD`)}
        lengthShowTooltip={10}
        containerClassName="mt-16"
      />

      <InputAutosize
        label={
          <Typography variant="body2" mr={1}>
            <b>Textarea Autosize Vertical</b>
          </Typography>
        }
        {...getFieldProps(`textareaAutosizeVertical`)}
        containerClassName="mt-16"
      />

      <TextareaAutosize
        label={
          <Typography variant="body2" mr={1}>
            <b>Textarea Autosize None Resize</b> (Auto resize when new row appears)
          </Typography>
        }
        {...getFieldProps(`textareaAutosizeHorizontal`)}
        resize="none"
        minRows={2}
        containerClassName="mt-16"
      />

      <Checkbox.Group
        label={
          <Typography variant="body2" mr={1}>
            <b>Checkbox</b>
          </Typography>
        }
        {...getFieldProps(`checkbox`)}
        options={[
          { label: 'Option 1', value: 'Option 1' },
          { label: 'Option 2', value: 'Option 2' },
          { label: 'Option 3', value: 'Option 3' },
        ]}
        onChange={setFieldValue}
        containerClassName="mt-16"
      />

      <Select
        label={'Select'}
        {...getFieldProps(`select`)}
        placeholder={'Search'}
        onBlur={setFieldTouched}
        options={[
          { label: 'Option 1', value: 1 },
          { label: 'Option 2', value: 2 },
          { label: 'Option 3', value: 3 },
        ]}
        onChange={setFieldValue}
        containerClassName="mt-16"
      />

      <RadioButton
        label={
          <Typography variant="body2" mr={1}>
            <b>Radio</b>
          </Typography>
        }
        {...getFieldProps(`radio`)}
        options={[
          { label: 'Option 1', value: 'Option 1' },
          { label: 'Option 2', value: 'Option 2' },
          { label: 'Option 3', value: 'Option 3' },
        ]}
        onChange={setFieldValue}
        containerClassName="mt-16"
      />

      <Button type="submit" className="mt-4">
        Submit
      </Button>
    </form>
  );
};

interface Props {}

export default memo(FormikDemo);
