import { yupResolver } from '@hookform/resolvers/yup';
import { Warning } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { Button } from 'src/components/common';
import {
  EllipsisTooltipFormInput,
  FormCheckboxGroup,
  FormCheckboxItem,
  FormDatePicker,
  FormInput,
  FormInputAutosize,
  FormInputCurrency,
  FormInputMask,
  FormInputPhone,
  FormTextareaAutosize,
} from 'src/components/reactHookFormComponents';
import FormRadioButton from 'src/components/reactHookFormComponents/FormRadioButton';
import FormSelect from 'src/components/reactHookFormComponents/FormSelect';
import { Yup } from 'src/services';
import { scrollToTopError } from 'src/utils';

export const demoFormSchema = Yup.object({
  input: Yup.string().required(),
  ellipsisTooltipInput: Yup.string().optional(),
  inputNumber: Yup.number().nullable().optional(),
});

export type DemoFormValues = {
  input: string;
  ellipsisTooltipInput: string;
  inputNumber: number;
  inputMask: string;
  currencyUSD: string;
  textareaAutosize: string;
  inputAutosize: string;
  checkbox: boolean;
  checkboxGroup: string[];
  select: string;
  radio: string;
  date: Date;
  dateRange: Date;
  phone: string;
  textEditor: string;
};

export const initialDemoFormValues = {
  input: '',
  ellipsisTooltipInput: '',
  inputNumber: null,
  inputMask: '',
  currencyUSD: '',
  textareaAutosize: '',
  inputAutosize: '',
  checkbox: false,
  checkboxGroup: [],
  select: null,
  radio: null,
  date: null,
  dateRange: null,
  phone: '',
  textEditor: '',
};

const ReactHookFormDemo: React.FC<Props> = () => {
  console.log('~~ ReactHookForm re-render');

  const {
    formState: { errors, touchedFields },
    control,
    setValue,
    handleSubmit,
    watch,
  } = useForm<DemoFormValues>({
    defaultValues: initialDemoFormValues,
    mode: 'onChange',
    shouldFocusError: true,
    //looking for enableReinitialize? => https://github.com/react-hook-form/react-hook-form/issues/181#issuecomment-1134899751
    resolver: yupResolver<any>(demoFormSchema),
  });

  const handleSubmitForm = handleSubmit(
    (data: DemoFormValues) => {
      console.log('ReactHookForm data', data);
    },
    (errors) => {
      console.log('errors: ', errors);
      scrollToTopError(Object.keys(errors));
    }
  );

  // const formValues = useWatch({control}) //Get all values
  const ellipsisTooltipInputValues = watch('ellipsisTooltipInput'); //Listen data of single field //Recommend: optimize performance
  // const ellipsisTooltipInputValues = useWatch({control, name: 'ellipsisTooltipInput'}); //Listen data of single field //Recommend: optimize performance

  return (
    <form id={'react-hook-form-demo'} noValidate onSubmit={handleSubmitForm}>
      <FormInput label={'Input'} control={control} name="input" placeholder="Input" required />
      <EllipsisTooltipFormInput
        label={'Ellipsis Tooltip Input - Type Over 15 characters will show tooltip and Warning'} //(Watch change => Re-render onChange)
        control={control}
        name="ellipsisTooltipInput"
        placeholder="Input"
        containerClassName="mt-4"
      />
      {ellipsisTooltipInputValues?.length > 15 && (
        <Stack direction={'row'} alignItems={'center'} mt={'4px'}>
          <Warning /> <Typography variant="body2">Too long text!</Typography>
        </Stack>
      )}
      <FormInput
        label={'Input Number'}
        control={control}
        name="inputNumber"
        placeholder="Input"
        containerClassName="mt-4"
        type={'number'}
      />
      <FormInputMask
        label={'Input Mask'}
        control={control}
        name="inputMask"
        placeholder="XXX-XXX"
        containerClassName="mt-4"
        mask={'999-999'}
      />
      <FormInputCurrency
        label={'Input Currency'}
        control={control}
        name="currencyUSD"
        setValue={setValue}
        placeholder="Input"
        containerClassName="mt-4"
      />
      <FormInputAutosize
        label={'Input Autosize Vertical'}
        control={control}
        name="inputAutosize"
        placeholder="Input"
        containerClassName="mt-4"
      />
      <FormTextareaAutosize
        label={'Text Autosize Vertical'}
        control={control}
        name="textareaAutosize"
        placeholder="Input"
        containerClassName="mt-4"
        minRows={3}
      />
      <FormCheckboxItem
        label={'Checkbox'}
        control={control}
        name="checkbox"
        placeholder="Input"
        required
        className="mt-4"
      />
      <FormCheckboxGroup
        label="Checkbox Group"
        control={control}
        name="checkboxGroup"
        containerClassName="mt-4"
        options={[
          { label: 'Option 1', value: 'Option 1' },
          { label: 'Option 2', value: 'Option 2' },
          { label: 'Option 3', value: 'Option 3' },
        ]}
        setValue={setValue}
      />
      <FormSelect
        label={'Select'}
        control={control}
        name="select"
        options={[
          { label: 'Option 1', value: 1 },
          { label: 'Option 2', value: 2 },
          { label: 'Option 3', value: 3 },
        ]}
        setValue={setValue}
        containerClassName="mt-4"
      />
      <FormRadioButton
        label={'Radio'}
        control={control}
        name="radio"
        options={[
          { label: 'Option 1', value: 'Option 1' },
          { label: 'Option 2', value: 'Option 2' },
          { label: 'Option 3', value: 'Option 3' },
        ]}
        setValue={setValue}
        containerClassName="mt-4"
      />
      <FormDatePicker
        label={'Date Picker'}
        control={control}
        name="date"
        setValue={setValue}
        placeholder="Select"
        containerClassName="mt-4"
      />
      <FormInputPhone
        label={'Phone'}
        control={control}
        name="phone"
        setValue={setValue}
        containerClassName="mt-4"
      />
      {/* <FormTextEditor
        label={'Text Editor'}
        control={control}
        name="textEditor"
        setValue={setValue}
        containerClassName="mt-4"
      /> */}

      <Button type="submit" className="mt-4">
        Submit
      </Button>

      <Typography mt={2}>Errors:</Typography>
      <ReactJson src={errors} />
      <Typography mt={2}>Touched Fields:</Typography>
      <ReactJson src={touchedFields} />
    </form>
  );
};

interface Props {}

export default memo(ReactHookFormDemo);
