export const PATHS = {
  root: '/',
  dev: '/dev',
  welcome: '/welcome',
  signIn: '/login',
  myAccount: '/my-accounts',
  dashboard: '/dashboard',
  logout: '/logout',

  // Profile
  myProfile: '/me',
  changePassword: '/me/change-password',
  editMyProfile: '/me/edit',
  configureNotification: '/me/notification',
  switchUser: '/switch-user',

  // miscellaneous / Users Managements
  userManagements: '/user-managements',
  addUser: '/user-managements/add',
  userDetail: '/user-managements/user-detail',
  rcuhStaffListing: '/staff-listing',
  searchAllDocuments: '/advanced-search',
  registerForePayments: '/register_for_epayments',
  vacationSickLeave: '/vacation_sick_audit',

  // Purchase Order
  createPurchaseOrders: '/purchase-orders/create',
  purchaseOrderDetail: '/purchase-orders/detail',
  purchaseOrderDetailNotFound: '/purchase-orders/detail/not-found',
  submittedPurchaseOrder: '/purchase-orders/submitted',
  poAdditionalForm: `/purchase-orders/additional-form`,

  // Purchasing
  purchasingOrders: '/purchasing-orders',

  // PO Change Form
  poChangeOptions: '/po-change-options',
  poChangeForm: '/po-change',
  poChangeFormNotFound: '/po-change/not-found',

  // PO Payment
  poPaymentForm: '/po-payment',
  poPaymentFormNotFound: '/po-payment/not-found',

  // Non PO Payment
  nonPOPaymentOptions: '/direct-payment-options',
  createAuthorizationForPayment: '/authorization-for-payment/create',
  authorizationForPaymentDetail: '/authorization-for-payment/detail',
  authorizationForPaymentDetailNotFound: '/authorization-for-payment/detail/not-found',

  createNonEmployeeTravelPayment: '/non-employee-travel-payment/create',
  nonEmployeeTravelPaymentDetail: '/non-employee-travel-payment/detail',
  nonEmployeeTravelPaymentDetailNotFound: '/non-employee-travel-payment/detail/not-found',

  createPersonalAutoPayment: '/personal-auto-payment/create',
  personalAutoPaymentDetail: '/personal-auto-payment/detail',
  personalAutoPaymentDetailNotFound: '/personal-auto-payment/detail/not-found',

  createPettyCashPayment: '/petty-cash-payment/create',
  pettyCashPaymentDetail: '/petty-cash-payment/detail',
  pettyCashPaymentDetailNotFound: '/petty-cash-payment/detail/not-found',

  reimbursementPayment: '/reimbursement-payment/detail',
  multiTravelPayment: '/multi-travel-payment/detail',

  submittedNonPOPayment: '/submitted-direct-payments',

  // Travel Request
  createTravelRequest: '/travel-request/create',
  travelRequestDetail: '/travel-request/detail',
  travelRequestDetailNotFound: '/travel-request/detail/not-found',
  submittedTravel: '/submitted-travel',

  // Travel Completion
  createTravelCompletion: '/travel-completion/create',
  travelCompletionDetail: '/travel-completion/detail',
  travelCompletionDetailNotFound: '/travel-completion/detail/not-found',

  // Travel Completion Listing
  travelListing: '/travel-listing',

  // Purchasing
  nonPOListing: '/direct-payments',

  // Vendor Print Mode
  vendorPrintMode: '/vendor-print-mode',

  // miscellaneous
  checkPrinting: '/check-printing',
  checkPrintingSuccess: '/check-printing-success',
  checkRegisterListing: '/check-registers',
  checkRegisterDetail: '/check-register/detail',

  // miscellaneous / Global settings
  globalSettings: '/global-settings',
  expiredPassword: '/expired-password',

  // miscellaneous / Vendors
  vendors: '/miscellaneous/vendors',
  addVendorMaster: '/miscellaneous/vendors/vendor-master/add',
  editVendorMaster: '/miscellaneous/vendors/vendor-master/edit',
  addVendorRegistration: '/miscellaneous/vendors/vendor-registration/add',
  vendorRegistrationDetail: '/miscellaneous/vendors/vendor-registration/detail',
  viewVendorRegistration: '/miscellaneous/vendors/vendor-registration/edit',

  // report
  uhProjectReports: '/uh-project-reports',
  rcuhProjectReports: '/rcuh-project-reports',
  financialForecastReports: '/financial-forecast-reports',
};

export const PATH_HEADERS = {
  [PATHS.myProfile]: 'My Profile',
  [PATHS.editMyProfile]: 'My Profile',
  [PATHS.changePassword]: 'Change Password', // pragma: allowlist secret
};

export const HIDE_NAV_PATHS = [];
