import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import TypographyLink from 'src/components/TypographyLink';
import { memo } from 'react';

const BreadcrumbsSearchAllDocument = () => {
  return (
    <Breadcrumbs>
      <Link to={PATHS.dashboard}>
        <TypographyLink>Main Menu</TypographyLink>
      </Link>
      <Typography variant="body2">Miscellaneous</Typography>
      <Typography variant="body2">Search All Documents</Typography>
    </Breadcrumbs>
  );
};

export default memo(BreadcrumbsSearchAllDocument);
