import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { TravelCompletionDetailResponse } from './types';

//use case: for get detail data without caching
export function useGetTravelCompletionDetailMutation(
  options?: UseMutationOptions<
    ApiResponseType<TravelCompletionDetailResponse>,
    Error,
    { id: string }
  >
) {
  const {
    mutate: getTravelCompletionDetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<TravelCompletionDetailResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) =>
      responseWrapper(apiClient.getTravelCompletionDetail, [params]),
    ...options,
  });

  return {
    getTravelCompletionDetailMutation,
    isLoading,
    isSuccess,
  };
}
