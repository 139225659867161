export interface CheckRegisterListingItem {
  id: string;

  achTotal: number;
  batchId: string;
  cardTotal: number;
  checkGrandTotal: string;
  checkPrintingId: number;
  createdDate?: string;
  date: string;
  endingCheckNumber: string;
  grandTotal: string;
  nvpGrandTotal: string;
  nvpTotal: number;
  paymentsTotal: number;
  return2Processed: false;
  // mailedChecksTotal: number;
  // returnedChecksTotal: number;
  startingCheckNumber: string;
  totalNumberOfChecks: number;
  versionNumber: number;
  mailedTotal: number;
  returnedTotal: number;
  updatedAt: string;
  createAt: string;
}

export enum CHECK_REGISTER_LISTING_ITEM_KEY {
  ID = 'id',

  CREATED_DATE = 'createdDate',
  STARTING_CHECK_NUMBER = 'startingCheckNumber',
  ENDING_CHECK_NUMBER = 'endingCheckNumber',
  RETURNED_TOTAL = 'returnedTotal',
  MAILED_TOTAL = 'mailedTotal',
  NVP_TOTAL = 'nvpTotal',
  PAYMENTS_TOTAL = 'paymentsTotal',
  CHECK_GRAND_TOTAL = 'checkGrandTotal',
  NVP_GRAND_TOTAL = 'nvpGrandTotal',
  GRAND_TOTAL = 'grandTotal',

  ACH_TOTAL = 'achTotal',
  BATCH_ID = 'batchId',
  CARD_TOTAL = 'cardTotal',
  CHECK_PRINTING_ID = 'checkPrintingId',
  DATE = 'date',
  RETURN_2_PROCESSED = 'return2Processed',
  TOTAL_NUMBER_OF_CHECKS = 'totalNumberOfChecks',
  VERSION_NUMBER = 'versionNumber',
}

export enum CHECK_REGISTER_DETAIL_VIEW {
  CHECKS = 'checks',
  PAYMENTS = 'payments',
  ALL = 'all',
}

export interface CheckRegisterDetail {
  id: number;

  checkPrintingId: number;
  batchId: string;
  date: string;
  createdDate: string;
  createdAt: string;
  versionNumber: number;
  return2Processed: boolean;
  startingCheckNumber: string;
  endingCheckNumber: string;

  // returnedChecksTotal: number;
  // mailedChecksTotal: number;
  mailedTotal: number;
  returnedTotal: number;

  totalNumberOfChecks: number;
  achTotal: number;
  cardTotal: number;
  nvpTotal: number;
  paymentsTotal: number;
  checkGrandTotal: string;
  nvpGrandTotal: string;
  grandTotal: string;
  lineItems: CheckRegisterLineItem[];
  countLineItems: CheckRegisterCountLineItem[];
}

export interface CheckRegisterLineItem {
  isSubtotalLine?: boolean;

  faCode: string;
  checkNumber: string;
  transactionCode: string;
  documentNumber: string;
  paymentRequestNumber: string;
  ckFlag?: string;
  vendorName: string;
  vendorCode: string;
  paymentType?: string;
  projectNumber: string;
  budgetCategory: string;
  originalFACode?: string;
  faFlag?: string;
  distFlag?: string;
  payReq?: any;
  mailed: string;
  amount: string;

  checkTotal: string;
  createdAt: string;
  originalFa: string;
  updatedAt: string;
}

export interface CheckRegisterCountLineItem {
  id: number;
  checkRegisterId: number;
  faCode: string;
  returnedCount: number;
  mailedCount: number;
  checksTotal: number;
  achCount: number;
  cardCount: number;
  nvpTotal: number;
  total: number;

  forder?: string;
  ckFlag?: boolean;
  createdAt?: string;
  updatedAt?: string;
}
