import { UseQueryOptions } from 'react-query';

export enum API_QUERIES {
  CONTENT = '/content',
  ZIP_CODE = '/zip-code',

  // Permissions
  MY_PERMISSIONS = '/permissions/me',
  PERMISSION_CU = '/permissions/cu',
  PI_CODES = '/pi-codes',
  FA_CODES = '/fa-codes',

  // Profile
  PROFILE = '/me',
  DELEGATION_ACCESSES = '/delegation-accesses',

  // Users Management
  USERS = '/users',
  USER = '/users/:id',
  SEARCH_USER = 'users/search',
  EXPORT_USERS = 'users/export',
  SEARCH_PROJECTS = 'projects/search',

  // staff listing
  GET_STAFF_LISTING = '/staff-listing',

  //check register
  GET_CHECK_REGISTER_LISTING = '/check-registers',
  GET_CHECK_REGISTER_DETAIL = '/check-registers/:id',

  //check printing
  GET_CURRENT_CHECK_PRINTING = '/check-printing/current',

  // Purchase Orders
  GET_PURCHASE_ORDERS = '/financial-svc/v1/purchase-orders',
  PO_DETAIL = '/purchase-orders/:id',
  PO_PAYMENT_DETAIL = '/po-payments/:id',
  PO_PAYMENT_REMAINING_BALANCE = 'po-payment/:id/remaining-balance',

  // Non PO Listing
  NON_PO_LISTING = '/direct-payments',

  // Travel Completion Listing
  TRAVEL_COMPLETION_LISTING = '/travel-completions',

  // Non PO Payment
  // Authorization
  AUTHORIZATION_DETAIL = '/authorization-payments/:id',
  // Non Employee Travel
  NON_EMPLOYEE_TRAVEL_DETAIL = '/non-employee-travel-payments/:id',
  // Petty Cash
  PETTY_CASH_DETAIL = '/petty-cash-payments/:id',
  // Personal Automobile
  PERSONAL_AUTOMOBILE_DETAIL = '/personal-auto-payments/:id',
  // ReimbursementForCash
  REIMBURSEMENT_FOR_CASH_DETAIL = '/reimbursements/:id',

  MULTI_INTRA_STATE_TRAVEL = '/multi-intra-state-travel-payments/:id',

  // Travel Request
  TRAVEL_REQUEST = '/travel-request/:id',
  // Travel Completion
  TRAVEL_COMPLETION = '/travel-completion/:id',

  // Vendor
  SEARCH_VENDOR = '/vendor/search',
  VENDORS = '/vendors',
  VENDORS_RECORDS = '/vendor-master',

  // Vendor Registration by id
  VENDOR_REGISTRATION = '/vendor-registration/code/:vendorCode',

  // Super Quotes
  SEARCH_QUOTE = '/super-quotes/numbers',
  IMPORT_SUPER_CODE = '/super-quotes/import',

  // Projects
  GET_FINANCIAL_PROJECTS = '/financial-svc/v1/projects',
  GET_ALL_FINANCIAL_PROJECTS = '/financial-svc/v1/projects?take=all',
  SEARCH_PROFILE_PROJECTS = '/financial-svc/v1/projects/me',

  // Global Settings
  GLOBAL_SETTINGS = 'global-settings',

  //Reports
  REPORT_USER_LIST = '/rcuh-reports/users',
  KFS_BUDGET_STATUS_REPORT = '/kfs-reports/kfs-budget-status',
  KFS_CONTINUATION_ACCOUNT_TRANSACTIONS_REPORT = '/kfs-reports/kfs-continuation-account-transactions',
  DAILY_BALANCE_REPORT = '/kfs-reports/daily-balance',
  KFS_MONTHLY_EXPENDITURES_REPORT = '/kfs-reports/kfs-monthly-expenditures',
  SUSPENSE_TRANSACTIONS_REPORT = '/kfs-reports/suspense-transactions',
  KFS_BUDGET_STATUS_SUMMARY_REPORT = '/kfs-reports/summary-budget-status',
  CURRENT_PROJECT_ASSIGNMENT = 'kfs-reports/current-project-assignment',
  MASS_PRINTING_REPORT = 'kfs-reports/mass-printing-report',

  // RCUH Reports
  RCUH_CURRENT_USER_ASSIGNMENT = '/rcuh-report/current-user-assignment',
  RCUH_CURRENT_PROJECT_ASSIGNMENT = '/rcuh-report/current-project-assignment',
  RCUH_BUDGET_STATUS_SUMMARY = '/rcuh-reports/project-report/budget-status-summary',
  RCUH_SUB_BUDGET_STATUS = '/rcuh-reports/project-report/sub-budget-status',
  RCUH_MASS_PRINTING_REPORT = '/rcuh-report/mass-printing-report',

  RCUH_PAYROLL_REPORT = '/rcuh-report/payroll-report',
  RCUH_DETAIL_OF_COST = '/rcuh-report/detail-of-cost',
  RCUH_BUDGET_CATEGORY = '/rcuh-report/project-report/budget-category',
  RCUH_VACATION_TRANS = '/rcuh-report/project-report/vacation-trans',
  RCUH_VACATION_SICK_LEAVE = '/rcuh-report/project-report/vacation-sick-leave',
  RCUH_SUMMARY_BUDGET_STATUS_REPORT = '/rcuh-report/project-report/summary-budget-status',
  RCUH_CONSOLIDATED_EXPENDITURE = '/rcuh-report/project-report/consolidated-expenditure',
  RCUH_HISTORIC_PAYROLL_REPORT = '/rcuh-report/historic-payroll-report',

  RCUH_VACATION_SICK_LEAVE_AUDIT = '/rcuh-report/vacation-sick-leave-audit',

  // Search All Document
  SEARCH_ALL_DOCUMENTS = '/documents',

  // Financial Reports
  FINANCIAL_KFS_BUDGET_STATUS = '/financial-reports/kfs-budget-status',
  FINANCIAL_RCUH_BUDGET_STATUS = '/financial-reports/rcuh-budget-status',
  FINANCIAL_PAYROLL_REPORT = '/financial-reports/payroll-report',
  FINANCIAL_NON_PAYROLL_REPORT = '/financial-reports/non-payroll-report',

  // Notification
  NOTIFICATION = 'notification',
}

export type QueryOptions<T> = Omit<UseQueryOptions, 'QueryKey'> & { QueryKey: T };
