import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { UHReportParams } from 'src/containers/UHReportContainer/types';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { SuspenseTransactionsReportResponse } from './types';

export function useGetSuspenseTransactionsReport(
  options?: UseQueryOptions<
    ApiResponseType<SuspenseTransactionsReportResponse>,
    Error,
    SuspenseTransactionsReportResponse
  > &
    Partial<UHReportParams>
) {
  const role = RoleService.getCurrentRole();
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<
    ApiResponseType<SuspenseTransactionsReportResponse>,
    Error,
    SuspenseTransactionsReportResponse
  >([API_QUERIES.SUSPENSE_TRANSACTIONS_REPORT, { project: options.project, role: role }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<SuspenseTransactionsReportResponse>>(
        apiClient.getSuspenseTransactionsReport,
        params
      );
    },
    select: getResponseData,
    ...options,
  });

  const queryClient = useQueryClient();
  const handleInvalidateSuspenseTransactionsReport = () =>
    queryClient.invalidateQueries(API_QUERIES.SUSPENSE_TRANSACTIONS_REPORT);

  return {
    data,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateSuspenseTransactionsReport,
  };
}
