import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, authResponseWrapper } from 'src/queries/helpers';
import { CheckPrintingAction, PatchCurrentCheckPrintingResponse } from './types';

export function usePatchCurrentCheckPrinting(
  options?: UseMutationOptions<
    ApiResponseType<{ data: PatchCurrentCheckPrintingResponse }>,
    Error,
    { action: CheckPrintingAction }
  >
) {
  const { mutate: patchCurrentCheckPrinting, isLoading } = useMutation<
    ApiResponseType<{ data: PatchCurrentCheckPrintingResponse }>,
    Error,
    { action: CheckPrintingAction }
  >({
    mutationFn: (params: { action: CheckPrintingAction }) => {
      return authResponseWrapper<ApiResponseType<{ data: PatchCurrentCheckPrintingResponse }>>(
        apiClient.patchCurrentCheckPrinting,
        [params]
      );
    },
    ...options,
  });

  return {
    patchCurrentCheckPrinting,
    isLoading,
  };
}
