import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { RoleService } from 'src/services';
import { GetReportUserListResponse } from './types';

export function useGetReportUserList(
  options?: UseQueryOptions<
    ApiResponseType<GetReportUserListResponse>,
    Error,
    GetReportUserListResponse
  >
) {
  const currentRole = RoleService.getCurrentRole();

  const {
    data: reportUserList,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetReportUserList,
  } = useQuery<ApiResponseType<GetReportUserListResponse>, Error, GetReportUserListResponse>(
    [
      API_QUERIES.REPORT_USER_LIST,
      {
        currentRole,
      },
    ],
    {
      queryFn: () => {
        return responseWrapper<ApiResponseType<GetReportUserListResponse>>(
          apiClient.getReportUserList
        );
      },
      select: getResponseData,
      enabled: false,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidateReportUserList = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_USER_LIST);

  return {
    reportUserList,
    error,
    isError,
    isLoading,
    onGetReportUserList,
    handleInvalidateReportUserList,
  };
}
// return new Promise((r) => {
//   setTimeout(() => {
//     r({ data: mockUserListData, code: 0, success: true, timestamp: '' });
//   }, 1000);
// });
