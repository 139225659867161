import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { NonEmployeeTravelDetailResponse } from './types';

//use case: for get detail data without caching
export function useGetNonEmployeeTravelDetailMutation(
  options?: UseMutationOptions<
    ApiResponseType<NonEmployeeTravelDetailResponse>,
    Error,
    { id: string }
  >
) {
  const {
    mutate: getEmployeeTravelDetailMutation,
    isLoading,
    isSuccess,
  } = useMutation<ApiResponseType<NonEmployeeTravelDetailResponse>, Error, { id: string }>({
    mutationFn: (params: { id: string }) =>
      responseWrapper(apiClient.getNonEmployeeTravelDetail, [params]),
    ...options,
  });

  return {
    getEmployeeTravelDetailMutation,
    isLoading,
    isSuccess,
  };
}
